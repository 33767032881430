﻿<!--
 © Copyright 2024 webzaytsev.ru.
 @author Nikita Zaytsev <nikita@webzaytsev.ru>
-->
<script setup lang="ts">
import products from '@/data/products.json';
import categories from '@/data/categories.json';
import { computed, ref, watch } from 'vue';

interface Category {
    id: number;
    title: string;
}

interface Product {
    title: string;
    article: number;
    image: string;
    categoryId: number;
}

interface ProductsByPet {
    title: string;
    image: string;
    products: Product[];
}

interface AngleData {
    start: number;
    offset: number;
}

const productList: ProductsByPet[] = products;
const categoryList: Category[] = categories;

const activePet = ref<ProductsByPet>(productList[0]);

const isMobile = window.matchMedia('(max-width: 767px)').matches;
const activeCategory = ref<Category>(categoryList[0]);
const baseAngles = isMobile ? [270, 320, 370, 170, 220] : [270, 305, 330, 210, 235];
const angles = ref([...baseAngles]);
const offsets = isMobile ? [50, 50, 50, 160, 50] : [35, 35, 25, 240, 25];

const activeProduct = computed(() => {
    return activePet.value.products.filter(
        (item: Product) => item.categoryId === activeCategory.value.id,
    );
});

function getActiveProductIdx(title: string): number {
    return activeProduct.value.findIndex((item: Product) => item.title === title);
}

function switchPet() {
    activePet.value = productList.find(
        (item: ProductsByPet) => item.title !== activePet.value.title,
    ) as ProductsByPet;
}

function angleData(): AngleData {
    const baseData = {
        start: 237,
        offset: 22,
    };

    if (window.matchMedia('(max-width: 767px)').matches) return { start: 258, offset: 8 };
    if (window.matchMedia('(max-width: 1023px)').matches) return { start: 252, offset: 12 };
    if (window.matchMedia('(max-width: 1280px)').matches) return { start: 246, offset: 16 };
    if (window.matchMedia('(max-width: 1440px)').matches) return { start: 240, offset: 20 };
    // if (window.matchMedia('(max-width: 1601px)').matches) return { start: 237, offset: 22 };

    return baseData;
}

function calculatedOffset() {
    if (window.matchMedia('(max-width: 1023px)').matches) return '27.9vw';
    if (window.matchMedia('(max-width: 1279px)').matches) return '34.8vw';
    return '349px';
}

watch(activeCategory, (newVal, oldVal) => {
    const newValId = newVal.id;
    const oldValId = oldVal.id;
    let diff = newValId - oldValId;

    if (diff === 3) {
        diff = -2;
    }

    if (diff === -3) {
        diff = 2;
    }

    const multiplier = (diff < 0 || diff === 4) && diff !== -4 ? 1 : -1;

    angles.value = angles.value.map((item, idx) => {
        if (Math.abs(diff) === 1) {
            let offsetIndex = idx - newValId + diff + 1;

            if (diff < 0) {
                offsetIndex = idx + 1 - newValId;
            }

            if (Math.abs(diff) === 4) {
                offsetIndex = idx === 4 ? 0 : idx + 1;
            }

            //@ts-ignore
            return item + offsets.at(offsetIndex) * multiplier;
        }

        if (Math.abs(diff) === 2) {
            if (oldValId === 1 && newValId === 3) {
                //@ts-ignore
                if (idx === 0) return item - offsets.at(4) - offsets.at(0);
                //@ts-ignore
                if (idx === 1) return item - offsets.at(0) - offsets.at(1);
                //@ts-ignore
                if (idx === 2) return item - offsets.at(1) - offsets.at(2);
                //@ts-ignore
                if (idx === 3) return item - offsets.at(2) - offsets.at(3);
                //@ts-ignore
                return item - offsets.at(3) - offsets.at(4);
            } else if (oldValId === 2 && newValId === 4) {
                //@ts-ignore
                if (idx === 0) return item - offsets.at(2) - offsets.at(3);
                //@ts-ignore
                if (idx === 1) return item - offsets.at(1) - offsets.at(2);
                //@ts-ignore
                if (idx === 2) return item - offsets.at(0) - offsets.at(1);
                //@ts-ignore
                if (idx === 3) return item - offsets.at(4) - offsets.at(0);
                //@ts-ignore
                return item - offsets.at(3) - offsets.at(4);
            } else if (oldValId === 3 && newValId === 5) {
                //@ts-ignore
                if (idx === 0) return item - offsets.at(3) - offsets.at(2);
                //@ts-ignore
                if (idx === 1) return item - offsets.at(4) - offsets.at(3);
                //@ts-ignore
                if (idx === 2) return item - offsets.at(0) - offsets.at(4);
                //@ts-ignore
                if (idx === 3) return item - offsets.at(1) - offsets.at(0);
                //@ts-ignore
                return item - offsets.at(2) - offsets.at(1);
            } else if (oldValId === 4 && newValId === 1) {
                //@ts-ignore
                if (idx === 0) return item - offsets.at(2) - offsets.at(1);
                //@ts-ignore
                if (idx === 1) return item - offsets.at(3) - offsets.at(2);
                //@ts-ignore
                if (idx === 2) return item - offsets.at(4) - offsets.at(3);
                //@ts-ignore
                if (idx === 3) return item - offsets.at(0) - offsets.at(4);
                //@ts-ignore
                return item - offsets.at(1) - offsets.at(0);
            } else if (oldValId === 5 && newValId === 2) {
                //@ts-ignore
                if (idx === 0) return item - offsets.at(0) - offsets.at(1);
                //@ts-ignore
                if (idx === 1) return item - offsets.at(2) - offsets.at(1);
                //@ts-ignore
                if (idx === 2) return item - offsets.at(3) - offsets.at(2);
                //@ts-ignore
                if (idx === 3) return item - offsets.at(4) - offsets.at(3);
                //@ts-ignore
                return item - offsets.at(1) - offsets.at(4);
            } else if (oldValId === 1 && newValId === 4) {
                //@ts-ignore
                if (idx === 0) return item + offsets.at(1) + offsets.at(2);
                //@ts-ignore
                if (idx === 1) return item + offsets.at(2) + offsets.at(3);
                //@ts-ignore
                if (idx === 2) return item + offsets.at(3) + offsets.at(4);
                //@ts-ignore
                if (idx === 3) return item + offsets.at(4) + offsets.at(0);
                //@ts-ignore
                return item + offsets.at(0) + offsets.at(1);
            } else if (oldValId === 2 && newValId === 5) {
                //@ts-ignore
                if (idx === 0) return item + offsets.at(0) + offsets.at(1);
                //@ts-ignore
                if (idx === 1) return item + offsets.at(1) + offsets.at(2);
                //@ts-ignore
                if (idx === 2) return item + offsets.at(2) + offsets.at(3);
                //@ts-ignore
                if (idx === 3) return item + offsets.at(3) + offsets.at(4);
                //@ts-ignore
                return item + offsets.at(4) + offsets.at(0);
            } else if (oldValId === 3 && newValId === 1) {
                //@ts-ignore
                if (idx === 0) return item + offsets.at(2) + offsets.at(1);
                //@ts-ignore
                if (idx === 1) return item + offsets.at(1) + offsets.at(0);
                //@ts-ignore
                if (idx === 2) return item + offsets.at(0) + offsets.at(4);
                //@ts-ignore
                if (idx === 3) return item + offsets.at(4) + offsets.at(3);
                //@ts-ignore
                return item + offsets.at(3) + offsets.at(2);
            } else if (oldValId === 4 && newValId === 2) {
                //@ts-ignore
                if (idx === 0) return item + offsets.at(3) + offsets.at(4);
                //@ts-ignore
                if (idx === 1) return item + offsets.at(4) + offsets.at(0);
                //@ts-ignore
                if (idx === 2) return item + offsets.at(0) + offsets.at(1);
                //@ts-ignore
                if (idx === 3) return item + offsets.at(1) + offsets.at(2);
                //@ts-ignore
                return item + offsets.at(2) + offsets.at(3);
            } else if (oldValId === 5 && newValId === 3) {
                //@ts-ignore
                if (idx === 0) return item + offsets.at(2) + offsets.at(3);
                //@ts-ignore
                if (idx === 1) return item + offsets.at(3) + offsets.at(4);
                //@ts-ignore
                if (idx === 2) return item + offsets.at(4) + offsets.at(0);
                //@ts-ignore
                if (idx === 3) return item + offsets.at(0) + offsets.at(1);
                //@ts-ignore
                return item + offsets.at(1) + offsets.at(2);
            }
        }

        let offsetIndex = idx - newValId + diff + 1;

        if (diff < 0) {
            offsetIndex = idx + 1 - newValId;
        }

        if (Math.abs(diff) === 4) {
            offsetIndex = idx === 4 ? 0 : idx + 1;
        }

        //@ts-ignore
        return item + offsets.at(offsetIndex) * multiplier;
    });
});

function setActiveCategory(newCatId: number): void {
    if (newCatId > 0 && newCatId <= categoryList.length) {
        activeCategory.value = categoryList.find((cat) => cat.id === newCatId) as Category;
        rotate.value = 0;
        return;
    }

    if (newCatId < 1) {
        //@ts-ignore
        activeCategory.value = categoryList.at(-1) as Category;
        rotate.value = 0;
        return;
    }

    activeCategory.value = categoryList[0];
    rotate.value = 0;
}

function isRight(id: number): boolean {
    let val: boolean;

    switch (activeCategory.value.id) {
        case 1:
            val = id === 2 || id === 3;
            break;
        case 2:
            val = id === 4 || id === 3;
            break;
        case 3:
            val = id === 4 || id === 5;
            break;
        case 4:
            val = id === 5 || id === 1;
            break;
        default:
            val = id === 1 || id === 2;
    }

    return val;
}

const touchStartX = ref<number | null>(null);
const touchEndX = ref<number | null>(null);
const isSwiping = ref(false);
const rotate = ref(0);

//@ts-ignore
function handleTouchStart(e) {
    touchStartX.value = e.touches[0].clientX;
    isSwiping.value = true;
}

//@ts-ignore
function handleTouchMove(e) {
    touchEndX.value = e.touches[0].clientX;
}

function handleTouchEnd() {
    if (!isSwiping.value || touchEndX.value === null) return;
    //@ts-ignore
    const deltaX = touchEndX.value - touchStartX.value;

    if (Math.abs(deltaX) > 20) {
        rotate.value = deltaX / 30;
    }
    isSwiping.value = false;
}
</script>

<template>
    <div class="mt-4" v-if="activeCategory">
        <div class="md:flex hidden items-center justify-between gap-x-5 mx-auto xl:w-83 w-70">
            <span
                class="w-5 h-5 cursor-pointer flex items-center justify-center"
                @click="setActiveCategory(activeCategory.id - 1)"
            >
                <svg width="10" viewBox="0 0 6 10" fill="none">
                    <path
                        d="M4.9184 1.55664L1.26965 5.20539L4.9184 8.85414"
                        stroke="#161345"
                        stroke-width="1.21625"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </span>

            <div class="xl:w-[250px] w-[200px] overflow-hidden">
                <div
                    class="transition-all duration-300 flex items-center"
                    :style="{ transform: `translateX(-${activeCategory.id - 1}00%)` }"
                >
                    <p
                        v-for="category in categoryList"
                        :key="category.id"
                        class="xl:w-[250px] w-[200px] font-gill xl:text-40 text-30 select-none leading-1.2 mt-1 whitespace-nowrap shrink-0 grow-0 text-center"
                    >
                        {{ category.title }}
                    </p>
                </div>
            </div>

            <span
                class="w-5 h-5 cursor-pointer flex items-center justify-center"
                @click="setActiveCategory(activeCategory.id + 1)"
            >
                <svg width="10" viewBox="0 0 6 10" fill="none">
                    <path
                        d="M1.08282 1.55688L4.73157 5.20563L1.08282 8.85438"
                        stroke="#161345"
                        stroke-width="1.21625"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </span>
        </div>
        <div class="2xl:pt-42 lg:pt-28 md:pt-63 pt-68 relative">
            <div
                class="w-[1540px] h-[1540px] rounded-full absolute z-10 left-1/2 -translate-x-1/2 lg:translate-y-[180px] md:translate-y-0 -translate-y-12 transition-all duration-500"
                @touchstart="handleTouchStart"
                @touchmove="handleTouchMove"
                @touchend="handleTouchEnd"
                :style="{
                    transform: `translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(${rotate}deg) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))`,
                }"
            >
                <div
                    class="transition-all absolute w-fit text-center origin-center top-1/2 left-1/2 lg:max-w-[250px] max-w-[100px] bg-turquoise-300"
                    v-for="(product, idx) in activePet.products"
                    :key="idx"
                    :style="{
                        transform: `translate(-50%, -50%) rotate(${
                            product.categoryId === activeCategory.id
                                ? angleData().start +
                                  angleData().offset * getActiveProductIdx(product.title)
                                : 270
                        }deg) translate(${
                            product.categoryId === activeCategory.id ? '900' : '-800'
                        }px) rotate(90deg)`,
                        'transition-duration': `1.${getActiveProductIdx(product.title) * 2}s`,
                    }"
                >
                    <RouterLink
                        :to="`/product/${product.article}`"
                        class="absolute z-30 top-0 left-0 w-full h-full"
                        :title="product.title"
                    />
                    <img
                        :src="`/img/product-slider/${product.image}`"
                        :alt="product.title"
                        class="2xl:max-w-[180px] lg:max-w-[150px] md:max-w-[120px] max-w-[90px] max-h-[310px] mx-auto object-contain object-center mix-blend-multiply"
                    />
                </div>
            </div>
            <div
                class="xl:w-[714px] lg:w-[71vw] w-[57vw] xl:h-[714px] lg:h-[71vw] h-[57vw] rounded-full bg-white md:border-[15px] border-[5px] border-[#C2E9E9] relative z-20 mx-auto cats-circle lg:translate-y-[43%] md:translate-y-[80px] translate-y-5"
            >
                <div
                    class="md:w-11 w-7 md:h-11 h-7 m-auto absolute top-1/2 left-1/2 origin-top-left transition-all duration-500"
                    v-for="category in categoryList"
                    :key="category.id"
                    :style="{
                        transform: `translate(-50%, -50%) rotate(${
                            angles[category.id - 1]
                        }deg) translate(${calculatedOffset()}) rotate(${
                            angles[category.id - 1] * -1
                        }deg)`,
                    }"
                    @click="setActiveCategory(category.id)"
                >
                    <span
                        class="w-full h-full block bg-[#F5FBFB] md:border-[10px] border-[3px] rounded-full cursor-pointer transition-all duration-500 border-[#C2E9E9]"
                        :class="{
                            'border-blue': activeCategory.id === category.id,
                        }"
                        @click="setActiveCategory(category.id)"
                    />
                    <span
                        class="absolute w-45 flex transition-all duration-300"
                        :class="{
                            '-translate-y-1/2': activeCategory.id !== category.id,
                            '-translate-x-1/2 justify-center md:-translate-y-20 -translate-y-14 left-1/2 top-1/2':
                                activeCategory.id === category.id,
                            'left-auto md:-right-49 -right-35 md:top-1/2 -top-[80%]':
                                activeCategory.id !== category.id && isRight(category.id),
                            'md:-left-49 -left-35 justify-end md:top-1/2 -top-[80%]':
                                activeCategory.id !== category.id && !isRight(category.id),
                        }"
                    >
                        <span
                            class="bg-white block md:px-5.5 px-3 md:py-2.5 py-1.5 md:text-16 text-14 rounded-full whitespace-nowrap cursor-pointer transition-all duration-500 md:w-40 w-fit text-center hover:border-blue border border-white"
                        >
                            {{ category.title }}
                        </span>
                    </span>
                </div>

                <div
                    class="absolute md:top-11 top-1/2 left-1/2 -translate-x-1/2 md:translate-y-0 -translate-y-1/2 md:flex flex-col items-center md:w-auto w-full"
                >
                    <div class="md:px-0 px-7.5">
                        <img
                            :src="`/img/${activePet.image}`"
                            alt="img"
                            height="180"
                            class="md:h-40.5 h-auto md:w-auto w-21 mx-auto md:relative absolute z-10 md:top-auto -top-14 md:left-auto left-19"
                        />
                        <p
                            class="font-gill xl:text-40 leading-none text-32 mb-2.5 mt-4 md:block hidden"
                        >
                            {{ activePet.title }}
                        </p>
                        <div
                            class="flex items-center md:justify-center justify-between md:gap-x-9 relative z-20"
                        >
                            <svg
                                @click="switchPet"
                                width="53"
                                height="53"
                                viewBox="0 0 53 53"
                                fill="none"
                                class="cursor-pointer group select-none md:w-auto h-auto w-7 fill-white hover:fill-blue"
                            >
                                <rect
                                    x="1.5"
                                    y="1.7"
                                    width="50"
                                    height="50"
                                    rx="25"
                                    stroke-width="2.5"
                                    class="stroke-turquoise-500 group-hover:stroke-blue"
                                />
                                <path
                                    d="M30.403 17.5L21.1993 26.7037L30.403 35.9074"
                                    class="stroke-blue group-hover:stroke-white"
                                    stroke-width="3"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                            <svg
                                @click="switchPet"
                                width="53"
                                height="53"
                                viewBox="0 0 53 53"
                                fill="none"
                                class="group cursor-pointer select-none md:w-auto h-auto w-7 fill-white hover:fill-blue"
                            >
                                <rect
                                    x="-1.31481"
                                    y="1.31481"
                                    width="50"
                                    height="50"
                                    rx="25"
                                    transform="matrix(-1 0 0 1 50.2179 0.407471)"
                                    class="stroke-turquoise-500 group-hover:stroke-blue"
                                    stroke-width="2.5"
                                />
                                <path
                                    d="M22.6068 17.5L31.8105 26.7037L22.6068 35.9074"
                                    class="stroke-blue group-hover:stroke-white"
                                    stroke-width="3"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
