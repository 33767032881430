﻿<!--
 © Copyright 2024 webzaytsev.ru.
 @author Nikita Zaytsev <nikita@webzaytsev.ru>
-->
<script setup lang="ts">
import IconSearch from '@/components/icons/IconSearch.vue';
import AppLogo from '@/components/images/AppLogo.vue';
import { ref } from 'vue';
import type { TProduct } from '@/types/TProduct';
import { debounce } from 'lodash';
import { getProducts } from '@/api/getProducts';

interface Props {
    isSearchBarShow: boolean;
}

defineProps<Props>();
const emit = defineEmits(['displaySearchBar']);

const loading = ref(false);
const search = ref('');
const products = ref<TProduct[]>([]);

let controller = new AbortController();

async function searchProducts(): Promise<void> {
    controller.abort();
    loading.value = true;

    if (search.value.length < 3) {
        products.value = [];
        loading.value = false;
        return;
    }

    controller = new AbortController();
    const signal = controller.signal;

    try {
        const formattedString = search.value.trim().toLowerCase();

        const data = await getProducts({ search: formattedString }, signal);

        // @ts-ignore
        if (data === 'abort') {
            return;
        }

        if (data && data?.data && data?.data.length) {
            products.value = data.data;
        }
        loading.value = false;
    } catch (e) {
        console.log(e);
        loading.value = false;
    }
}

const debouncedHandler = debounce(searchProducts, 1000);
</script>

<template>
    <div
        v-if="isSearchBarShow"
        class="lg:absolute fixed lg:w-[300px] md:w-[calc(100vw_-_295px)] bg-white lg:rounded-b-20 lg:-left-5 -left-[calc(100vw_-_295px)] lg:pt-5 md:pt-20 pt-10 pb-4 lg:top-[calc(100%_+_18px)] top-0"
    >
        <div class="md:hidden block">
            <AppLogo class="w-[168px] h-auto mx-auto mb-6.5" />
            <button
                class="rounded-full flex items-center justify-center py-2.5 px-5.5 bg-[#F1F1F1] gap-x-2.5 w-fit mx-auto mb-5"
                @click="emit('displaySearchBar')"
            >
                <span>В меню</span>
            </button>
        </div>
        <div class="lg:px-2.5 md:px-10 px-5">
            <div
                class="w-full flex items-center border border-blue rounded-full overflow-hidden py-1.5 px-3 gap-x-2.5"
            >
                <IconSearch class="w-4 h-auto" />
                <input
                    type="text"
                    placeholder="Поиск"
                    class="w-full block bg-white p-0 focus:outline-none"
                    v-model="search"
                    @input="debouncedHandler"
                />
            </div>
        </div>
        <div
            class="lg:max-h-[400px] lg:h-auto h-full overflow-y-auto search-list lg:px-2.5 md:px-10 px-5"
        >
            <p class="" v-if="loading" v-html="'Загружаем..'" />
            <template v-else>
                <ul class="grid gap-y-3 pt-5.5" v-if="products.length">
                    <li class="" v-for="product in products" :key="product.id">
                        <RouterLink
                            :to="`/product/${product.article}`"
                            class="px-[7px] py-[3px] flex w-full items-center justify-between group gap-x-1"
                            title="Brit Premium Cat Adult Chicken с курицей для взрослых кошек"
                        >
                            <img
                                v-if="product.image"
                                :src="product.image"
                                loading="lazy"
                                alt="img"
                                width="67"
                                height="67"
                                class="object-center object-contain"
                            />
                            <span class="w-full font-roboto">
                                <span
                                    class="flex items-center"
                                    :class="{
                                        'gap-x-2 justify-between':
                                            product.article && product?.special_status?.active,
                                    }"
                                >
                                    <span
                                        class="text-blue opacity-30 text-14"
                                        v-if="product.article"
                                        v-html="product.article"
                                    />
                                    <span
                                        v-if="product?.special_status?.active"
                                        class="text-white text-14 rounded-full px-2.5 py-0.5 bg-[#95C11F] block"
                                        v-html="product.special_status.title"
                                    />
                                </span>

                                <span
                                    class="group-hover:underline line-clamp-2 font-bold"
                                    v-html="product.title"
                                />
                                <span
                                    v-if="product.variations?.length"
                                    class="flex items-center xl:gap-x-3 gap-x-2 gap-y-2 xl:flex-nowrap flex-wrap"
                                >
                                    <span
                                        v-for="variation in product.variations"
                                        :key="variation.article"
                                        class="md:px-2.5 px-1 md:py-1.5 py-0.5 border border-[#EDEDED] rounded-full whitespace-nowrap font-roboto text-14 leading-none"
                                        v-text="`${variation.measurements} ${variation.packing}`"
                                    />
                                </span>
                            </span>
                        </RouterLink>
                    </li>
                </ul>
                <p
                    class="mt-3 text-14"
                    v-if="search.length < 3"
                    v-text="'Введите более 2х символов, чтобы начать поиск'"
                />

                <p
                    class="mt-3 text-14"
                    v-if="search.length >= 3 && !products.length"
                    v-text="`По запросу '${search}' ничего не найдено.`"
                />
            </template>
        </div>
    </div>
</template>

<style scoped lang="scss">
.search-list {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    &::-webkit-scrollbar-track {
        background: var(--turquoise-color);
    }

    &::-webkit-scrollbar-thumb {
        background: var(--turquoise-color-600);
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #888;
    }
}
</style>
