﻿<!--
 © Copyright 2024 webzaytsev.ru.
 @author Nikita Zaytsev <nikita@webzaytsev.ru>
-->
<script setup lang="ts">
// import feedPack from '@/assets/images/six-section-feed-pack-2x.png';
import feedPack from '@/assets/images/brit__dog.png';
import feedPackOpen from '@/assets/images/feed__pack-open.png';
import logoPrem from '@/assets/images/brit-premium-logo.svg';
import IconSixSection1 from '@/components/icons/IconSixSection1.vue';
import IconSixSection2 from '@/components/icons/IconSixSection2.vue';
import IconSixSection3 from '@/components/icons/IconSixSection3.vue';
import IconSixSection4 from '@/components/icons/IconSixSection4.vue';
import IconSixSection5 from '@/components/icons/IconSixSection5.vue';
import IconSixSection6 from '@/components/icons/IconSixSection6.vue';
import SixSectionFish from '@/components/images/SixSectionFish.vue';
import Ham1 from '@/components/images/Ham1.vue';
import Ham2 from '@/components/images/Ham2.vue';
import Meat from '@/components/images/Meat.vue';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import MotionPathPlugin from 'gsap/MotionPathPlugin';
import { onMounted, ref } from 'vue';
import { start } from 'repl';

gsap.registerPlugin(ScrollTrigger, MotionPathPlugin);

const wrap = ref(null);

function animateIcon() {
    if (window.matchMedia('(max-width: 1024px)').matches) return;
    gsap.set(['#icon1', '#icon2', '#icon3', '#icon4', '#icon5', '#icon6'], {
        scale: 0.2,
        opacity: 1,
    });
    gsap.timeline({
        scrollTrigger: {
            trigger: wrap.value,
            start: '-=0%',
            end: '+=850%',
            scrub: true,
        },
    })
        .to('#icon1', {
            scale: 1,
            ease: 'power1.inOut',
            motionPath: {
                path: '#path',
                align: '#path',
                alignOrigin: [0.5, 0.5],
                start: 1,
                end: 0,
            },
        })
        .to(
            '#icon2',
            {
                stagger: {
                    each: 0.2,
                    from: 'start',
                },
                scale: 1,
                ease: 'power1.inOut',
                motionPath: {
                    path: '#path',
                    align: '#path',
                    alignOrigin: [0.5, 0.5],
                    start: 1,
                    end: 0.125,
                },
            },
            0,
        )
        .to(
            '#icon3',
            {
                stagger: {
                    each: 0.4,
                    from: 'start',
                },
                scale: 1,
                ease: 'power1.inOut',
                motionPath: {
                    path: '#path',
                    align: '#path',
                    alignOrigin: [0.5, 0.5],
                    start: 1,
                    end: 0.26,
                },
            },
            0,
        )
        .to(
            '#icon4',
            {
                stagger: {
                    each: 0.6,
                    from: 'start',
                },
                scale: 1,
                ease: 'power1.inOut',
                motionPath: {
                    path: '#path',
                    align: '#path',
                    alignOrigin: [0.5, 0.5],
                    start: 1,
                    end: 0.41,
                },
            },
            0,
        )
        .to(
            '#icon5',
            {
                stagger: {
                    each: 0.8,
                    from: 'start',
                },
                scale: 1,
                ease: 'power1.inOut',
                motionPath: {
                    path: '#path',
                    align: '#path',
                    alignOrigin: [0.5, 0.5],
                    start: 1,
                    end: 0.55,
                },
            },
            0,
        )
        .to(
            '#icon6',
            {
                stagger: {
                    each: 1,
                    from: 'start',
                },
                scale: 1,
                ease: 'power1.inOut',
                motionPath: {
                    path: '#path',
                    align: '#path',
                    alignOrigin: [0.5, 0.5],
                    start: 1,
                    end: 0.67,
                },
            },
            0,
        )
        .to(
            '#icon1',
            {
                x: '223.438%',
                y: '70.5035%',
                alignOrigin: [0.5, 0.5],
                rotate: '180deg',
                scale: 0.8,
                ease: 'power1.inOut',
            },
            0.5,
        )
        .to(
            '#icon2',
            {
                x: '40.8471%',
                y: '83.1223%',
                alignOrigin: [0.5, 0.5],
                rotate: '180deg',
                scale: 0.8,
                ease: 'power1.inOut',
            },
            0.5,
        )
        .to(
            '#icon3',
            {
                x: '-85.212%',
                y: '5.396%',
                alignOrigin: [0.5, 0.5],
                rotate: '180deg',
                scale: 0.8,
                ease: 'power1.inOut',
            },
            0.5,
        )
        .to(
            '#icon4',
            {
                x: '-70.719%',
                y: '-104.231%',
                alignOrigin: [0.5, 0.5],
                rotate: '180deg',
                scale: 0.8,
                ease: 'power1.inOut',
            },
            0.5,
        )
        .to(
            '#icon5',
            {
                x: '-30.7713%',
                y: ' -73.378%',
                alignOrigin: [0.5, 0.5],
                rotate: '180deg',
                scale: 0.8,
                ease: 'power1.inOut',
            },
            0.5,
        )
        .to(
            '#icon6',
            {
                x: '-67.7975%',
                y: '-12.4941%',
                alignOrigin: [0.5, 0.5],
                rotate: '180deg',
                scale: 0.8,
                ease: 'power1.inOut',
            },
            0.5,
        )
        .to(
            '#rotate',
            {
                alignOrigin: [0.5, 0.5],
                rotate: '-180deg',
                y: '20%',
                ease: 'power1.inOut',
            },
            0.5,
        )

        .to(
            '#icon1',
            {
                y: '30.%',
                ease: 'power1.inOut',
                opacity: 0,
            },
            1,
        )
        .to(
            '#rotate',
            {
                alignOrigin: [0.5, 0.5],
                rotate: '-245deg',
                ease: 'power1.inOut',
            },
            1.4,
        )
        .to(
            '#icon2',
            {
                rotate: '245deg',
                ease: 'power1.inOut',
            },
            1.4,
        )
        .to(
            '#icon3',
            {
                rotate: '245deg',
                ease: 'power1.inOut',
            },
            1.4,
        )
        .to(
            '#icon4',
            {
                rotate: '245deg',
                ease: 'power1.inOut',
            },
            1.4,
        )
        .to(
            '#icon5',
            {
                rotate: '245deg',
                ease: 'power1.inOut',
            },
            1.4,
        )
        .to(
            '#icon6',
            {
                rotate: '245deg',
                ease: 'power1.inOut',
            },
            1.4,
        )
        .to(
            '#icon2',
            {
                y: '60.%',
                x: '80%',
                ease: 'power1.inOut',
                opacity: 0,
            },
            1.5,
        )
        .to(
            '#rotate',
            {
                alignOrigin: [0.5, 0.5],
                rotate: '-293deg',
                // y: '20%',
                ease: 'power1.inOut',
            },
            1.9,
        )
        .to(
            '#icon3',
            {
                rotate: '285deg',
                ease: 'power1.inOut',
            },
            1.9,
        )
        .to(
            '#icon4',
            {
                rotate: '285deg',
                ease: 'power1.inOut',
            },
            1.9,
        )
        .to(
            '#icon5',
            {
                rotate: '285deg',
                ease: 'power1.inOut',
            },
            1.9,
        )
        .to(
            '#icon6',
            {
                rotate: '285deg',
                ease: 'power1.inOut',
            },
            1.9,
        )
        .to(
            '#icon3',
            {
                y: '40.%',
                x: '-30%',
                ease: 'power1.inOut',
                opacity: 0,
            },
            2,
        )
        .to(
            '#rotate',
            {
                alignOrigin: [0.5, 0.5],
                rotate: '-359deg',
                // y: '20%',
                ease: 'power1.inOut',
            },
            2.4,
        )
        .to(
            '#icon4',
            {
                rotate: '355deg',
                ease: 'power1.inOut',
            },
            2.4,
        )
        .to(
            '#icon5',
            {
                rotate: '355deg',
                ease: 'power1.inOut',
            },
            2.4,
        )
        .to(
            '#icon6',
            {
                rotate: '355deg',
                ease: 'power1.inOut',
            },
            2.4,
        )
        .to(
            '#icon4',
            {
                y: '-25.%',
                x: '-66%',
                ease: 'power1.inOut',
                opacity: 0,
            },
            2.5,
        )
        .to(
            '#rotate',
            {
                alignOrigin: [0.5, 0.5],
                rotate: '-425deg',
                // y: '20%',
                ease: 'power1.inOut',
            },
            2.9,
        )
        .to(
            '#icon5',
            {
                rotate: '425deg',
                ease: 'power1.inOut',
            },
            2.9,
        )
        .to(
            '#icon6',
            {
                rotate: '425deg',
                ease: 'power1.inOut',
            },
            2.9,
        )
        .to(
            '#icon5',
            {
                y: '-45%',
                x: '-77%',
                ease: 'power1.inOut',
                opacity: 0,
            },
            3,
        )
        .to(
            '#rotate',
            {
                alignOrigin: [0.5, 0.5],
                rotate: '-473deg',
                // y: '20%',
                ease: 'power1.inOut',
            },
            3.4,
        )
        .to(
            '#icon6',
            {
                rotate: '475deg',
                ease: 'power1.inOut',
            },
            3.4,
        )
        .to(
            '#icon6',
            {
                y: '-30.%',
                x: '-125%',
                ease: 'power1.inOut',
                opacity: 0,
            },
            3.5,
        );
}
function animateFeed() {
    if (window.matchMedia('(max-width: 1024px)').matches) return;
    gsap.set(['#six-sec-ham1', '#six-sec-fish1', '#six-sec-ham2'], {
        opacity: 1,
        yPercent: 100,
    });
    gsap.set('#six-sec-meat', {
        opacity: 1,
        yPercent: 30,
    });

    gsap.timeline({
        scrollTrigger: {
            trigger: wrap.value,
            start: '-=0%',
            end: '+=120%',
            scrub: true,
        },
    })
        .to(
            '#feed-pack-open',
            {
                opacity: 1,
                ease: 'power1.inOut',
            },
            1,
        )
        .to(
            '#six-sec-fish1',
            {
                yPercent: 7,
            },
            '<',
        )
        .to(
            '#six-sec-ham1',
            {
                yPercent: 3,
            },
            '<',
        )
        .to(
            '#six-sec-ham2',
            {
                yPercent: -50,
            },
            '<',
        )
        .to(
            '#six-sec-meat',
            {
                yPercent: 0,
            },
            '<',
        );
}
function animateFeedClose() {
    if (window.matchMedia('(max-width: 1024px)').matches) return;
    gsap.timeline({
        scrollTrigger: {
            trigger: wrap.value,
            start: '-=0%',
            end: '+=1050%',
            pin: true,
            scrub: true,
        },
    })

        .to(
            '#feed-pack',
            {
                opacity: 1,
                ease: 'power1.inOut',
            },
            1,
        )
        .to(
            '#feed-pack-open',
            {
                scale: 0,
                ease: 'power1.inOut',
            },
            '>',
        );
}
onMounted(() => {
    animateFeedClose(), animateFeed(), animateIcon();
});
</script>

<template>
    <svg
        viewBox="0 0 1735 883"
        fill="none"
        class="absolute left-auto -right-[11.23%] lg:block hidden top-[150px] opacity-0 w-[90.59%] h-auto"
    >
        <path
            id="path"
            d="M1 466.527C14.6313 319.809 63.8078 228.444 172.384 128.83C280.961 29.2152 433.154 1.99912 580.5 1.02667C727.846 0.054219 859.827 25.3948 969.709 123.567C1079.59 221.74 1103.98 421.604 1120 476.527C1156.82 602.779 1251 881.527 1734.5 881.527"
            stroke="#00FF38"
        />
    </svg>
    <div
        class="lg:pt-20 md:pt-10 xl:h-[884px] lg:h-[730px] md:h-112.5 md:pb-0 pb-8.5 overflow-hidden px-5 lg:min-h-screen"
        ref="wrap"
    >
        <div
            id="relative"
            class="max-w-[1290px] mx-auto relative xl:pt-40 lg:pt-55 md:pt-35 md:h-auto h-81"
        >
            <img
                id="feed-pack"
                :src="feedPack"
                alt="feed pack"
                width="700"
                height="700"
                style="left: 27.5%"
                class="lg:w-[45%] md:w-101 w-47 h-600 mx-auto absolute left-auto opacity-0 z-10"
            />
            <img
                id="feed-pack-open"
                :src="feedPackOpen"
                alt="feed pack open"
                width="700"
                height="700"
                class="lg:w-[45%] md:w-101 w-47 h-auto mx-auto md:relative relative absolute opacity-0 z-5"
            />
            <div
                class="absolute w-full md:max-w-none max-w-82.5 md:mx-0 mx-auto xl:h-[622px] lg:h-[520px] md:h-85 h-81 left-0 top-0"
            >
                <img
                    style="z-index: 10"
                    :src="logoPrem"
                    alt="brit"
                    width="356"
                    height="285"
                    class="absolute hg:-left-20 left-0 lg:-top-12.5 -top-8 h-auto 2xl:w-[267px] lg:w-50 w-35 md:block hidden"
                />
                <div
                    id="rotate"
                    class="absolute w-full md:max-w-none max-w-82.5 md:mx-0 mx-auto xl:h-[622px] lg:h-[520px] md:h-85 h-81 left-0 top-0"
                >
                    <!--Icons-->
                    <IconSixSection1
                        style="z-index: 0"
                        id="icon1"
                        class="absolute left-0 md:top-auto top-1/2 md:translate-y-0 -translate-y-1/2 md:bottom-0 z-20 2xl:w-auto lg:w-[13.5%] h-auto md:w-22.5 w-16.5"
                    />
                    <IconSixSection2
                        style="z-index: 0"
                        id="icon2"
                        class="absolute left-[6.82%] md:top-[27.6%] top-13 z-20 2xl:w-auto lg:w-[13.5%] h-auto md:w-22.5 w-16.5"
                    />
                    <IconSixSection3
                        style="z-index: 0"
                        id="icon3"
                        class="absolute left-[28.5%] top-0 z-20 2xl:w-auto lg:w-[13.5%] h-auto md:w-22.5 w-16.5"
                    />
                    <IconSixSection4
                        style="z-index: 0"
                        id="icon4"
                        class="absolute left-auto right-[28.5%] top-0 z-20 2xl:w-auto lg:w-[13.5%] h-auto md:w-22.5 w-16.5"
                    />
                    <IconSixSection5
                        style="z-index: 0"
                        id="icon5"
                        class="absolute left-auto right-[6.82%] md:top-[27.6%] top-13 z-20 2xl:w-auto lg:w-[13.5%] h-auto md:w-22.5 w-16.5"
                    />
                    <IconSixSection6
                        style="z-index: 0"
                        id="icon6"
                        class="absolute left-auto right-0 md:top-auto top-1/2 md:translate-y-0 -translate-y-1/2 md:bottom-0 z-20 2xl:w-auto lg:w-[13.5%] h-auto md:w-22.5 w-16.5"
                    />
                </div>

                <IconSixSection3
                    class="absolute z-20 h-auto w-16.5 md:hidden block top-auto bottom-13 left-[6.82%]"
                />
                <IconSixSection4
                    class="absolute z-20 h-auto w-16.5 md:hidden block top-auto bottom-0 left-[28.5%]"
                />
                <IconSixSection6
                    class="absolute z-20 h-auto w-16.5 md:hidden block top-auto bottom-0 left-auto right-[28.5%]"
                />
                <IconSixSection2
                    class="absolute z-20 h-auto w-16.5 md:hidden block top-auto bottom-13 left-auto right-[6.82%]"
                />
                <!--End Icons-->

                <!--Decor-->
                <SixSectionFish
                    class="absolute z-10 left-auto md:top-auto top-1/2 md:right-11.5 right-3 md:-bottom-[7.4%] lg:w-auto h-auto md:w-35 w-19.5"
                    id="six-sec-fish1"
                />
                <SixSectionFish
                    class="md:hidden block w-19.5 h-auto absolute z-10 left-4.5 top-auto bottom-5"
                />
                <Ham1
                    class="absolute z-10 md:top-[26.2%] top-12 left-auto md:right-[23.25%] right-15 lg:w-auto h-auto md:w-27.5 w-15.5"
                    id="six-sec-ham1"
                />
                <Ham1
                    class="md:hidden block w-15.5 h-auto absolute z-10 left-auto right-10 top-auto bottom-7"
                />
                <Ham2
                    class="absolute z-10 md:top-auto top-1/2 md:-bottom-[11.57%] md:left-[6.82%] left-9.5 lg:w-auto h-auto md:w-24.5 w-14"
                    id="six-sec-ham2"
                />
                <Meat
                    class="absolute z-10 md:top-[22.5%] top-10 md:left-[12.55%] left-7 lg:w-auto h-auto md:w-25 w-14"
                    id="six-sec-meat"
                />
                <Meat
                    class="absolute z-10 md:hidden block h-auto w-14 top-auto -bottom-8.5 left-28"
                />
                <!--End Decor-->
            </div>
        </div>
    </div>
</template>

<style scoped>
html {
    font-size: calc(100vw / 1920);
}
@media (min-width: 1440px) {
    #icon1 {
        width: 14.5rem;
        height: 14.5rem;
    }
    #icon2 {
        width: 14.5rem;
        height: 14.5rem;
    }
    #icon3 {
        width: 14.5rem;
        height: 14.5rem;
    }
    #icon4 {
        width: 14.5rem;
        height: 14.5rem;
    }
    #icon5 {
        width: 14.5rem;
        height: 14.5rem;
    }
    #icon6 {
        width: 14.5rem;
        height: 14.5rem;
    }
}

@media (min-width: 1200px) and (max-width: 1440px) {
    #icon1 {
        width: 14.1rem;
        height: 14.1rem;
    }
    #icon2 {
        width: 14.1rem;
        height: 14.1rem;
    }
    #icon3 {
        width: 14.1rem;
        height: 14.1rem;
    }
    #icon4 {
        width: 14.1rem;
        height: 14.1rem;
    }
    #icon5 {
        width: 14.1rem;
        height: 14.1rem;
    }
    #icon6 {
        width: 14.1rem;
        height: 14.1rem;
    }
}
@media (min-width: 1024px) and (max-width: 1200px) {
    #icon1 {
        width: 11.7rem;
        height: 11.3rem;
    }
    #icon2 {
        width: 11.7rem;
        height: 11.7rem;
    }
    #icon3 {
        width: 11.7rem;
        height: 11.7rem;
    }
    #icon4 {
        width: 11.7rem;
        height: 11.7rem;
    }
    #icon5 {
        width: 11.7rem;
        height: 11.7rem;
    }
    #icon6 {
        width: 11.7rem;
        height: 11.7rem;
    }
}
@media (min-width: 0px) and (max-width: 1024px) {
    #feed-pack {
        opacity: 1;
        left: auto !important;
    }
    #relative {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
