﻿<!--
 © Copyright 2024 webzaytsev.ru.
 @author Nikita Zaytsev <nikita@webzaytsev.ru>
-->
<script setup lang="ts">
import { onBeforeMount, ref } from 'vue';
import hugeDog from '@/components/home-page/quiz/images/dog.png';
import middleDog from '@/components/home-page/quiz/images/middle-dog.png';
import smallDog from '@/components/home-page/quiz/images/small-dog.png';
import DogBg from '@/components/home-page/quiz/DogBg.vue';

const items = [
    { text: 'Крупный', img: { src: hugeDog, w: 199 } },
    { text: 'Средний', img: { src: middleDog, w: 184 } },
    { text: 'Маленький', img: { src: smallDog, w: 164 } },
];
const activeItem = ref();
const emit = defineEmits(['setSize']);

function selectSize(idx: number): void {
    activeItem.value = items[idx];
    emit('setSize', items[idx].text);
}

onBeforeMount(() => selectSize(0));
</script>

<template>
    <div class="h-full flex flex-col justify-end">
        <div
            v-for="({ text, img }, idx) in items"
            :key="idx"
            class="md:h-auto h-[calc(100%_-_45px)] relative z-10"
            :class="{
                hidden: text !== activeItem.text,
            }"
        >
            <div class="relative h-full">
                <img
                    :src="img.src"
                    :width="img.w"
                    alt="img"
                    class="h-auto max-h-full md:w-[unset] w-auto absolute left-1/2 -translate-x-1/2 top-auto bottom-0 z-20"
                />
                <DogBg class="absolute z-10 left-1/2 -translate-x-1/2 top-auto bottom-9" />
                <img
                    :src="items[0].img.src"
                    :width="items[0].img.w"
                    alt="img"
                    class="h-auto opacity-0"
                />
            </div>
        </div>

        <div class="flex items-center justify-center gap-1.5 md:my-5 my-2.5 relative z-20">
            <div
                class="rounded-full w-20 h-3 cursor-pointer"
                :class="{
                    'bg-turquoise-300': text !== activeItem.text,
                    'bg-turquoise-600': text === activeItem.text,
                }"
                v-for="({ text }, idx) in items"
                :key="idx"
                @click="selectSize(idx)"
            />
        </div>
        <p class="text-center font-gill leading-1.2" v-html="activeItem.text" />
    </div>
</template>
