﻿<!--
 © Copyright 2024 webzaytsev.ru.
 @author Nikita Zaytsev <nikita@webzaytsev.ru>
-->
<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue';
import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import slide1 from '@/assets/images/home-big-slider-1.jpg';
import slide2 from '@/assets/images/home-big-slider-2.jpg';
import slide3 from '@/assets/images/home-big-slider-3.jpg';
import slide4 from '@/assets/images/home-big-slider-4.jpg';
import slide5 from '@/assets/images/home-big-slider-5.jpg';
import slide1mob from '@/assets/images/home-big-slider-1-mob.jpg';
import slide2mob from '@/assets/images/home-big-slider-2-mob.jpg';
import slide3mob from '@/assets/images/home-big-slider-3-mob.jpg';
import slide4mob from '@/assets/images/home-big-slider-4-mob.jpg';
import slide5mob from '@/assets/images/home-big-slider-5-mob.jpg';

const isMobile = window.matchMedia('(max-width: 767px)').matches;

const slides = [
    {
        img: slide2,
        imgMob: slide2mob,
        link: 'https://youtu.be/N6t31mWh4aI?si=ESMcgLKij5ZRI-Nd',
    },
    {
        img: slide3,
        imgMob: slide3mob,
        link: '/news/2',
    },
    {
        img: slide1,
        imgMob: slide1mob,
        link: '/news/4',
    },
    {
        img: slide4,
        imgMob: slide4mob,
        link: '/news/1',
    },
    {
        img: slide5,
        imgMob: slide5mob,
        link: '/news/5',
    },
];

const modules = [EffectCoverflow, Pagination, Navigation];

function spaceBetween(): number {
    if (window.matchMedia('(max-width: 767px)').matches) return 35;
    if (window.matchMedia('(max-width: 1024px)').matches) return 70;

    return 100;
}
</script>

<template>
    <div class="second-section md:pt-8 md:pb-20 pb-10 overflow-hidden">
        <swiper
            slides-per-view="auto"
            :grabCursor="true"
            :centeredSlides="true"
            effect="coverflow"
            :freeMode="true"
            :modules="modules"
            :loop="true"
            :navigation="{}"
            :coverflowEffect="{
                rotate: 0,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: true,
            }"
            :space-between="spaceBetween()"
            :pagination="{ clickable: true }"
            :mousewheel="{
                forceToAxis: true,
            }"
        >
            <swiper-slide
                v-for="(slide, idx) in slides"
                :key="idx"
                class="w-fit xl:max-w-[1106px] lg:max-w-[800px] md:max-w-[650px] max-w-[75vw] md:rounded-30 rounded-10 overflow-hidden select-none"
            >
                <RouterLink
                    :to="slide.link"
                    v-if="slide.link && !slide.link.includes('http')"
                    class="absolute w-full h-full z-20 top-0 left-0"
                />
                <a :href="slide.link"
                   v-else
                   class="absolute w-full h-full z-20 top-0 left-0"
                />
                <figure class="relative z-10">
                    <img
                        :src="isMobile ? slide.imgMob : slide.img"
                        alt="img"
                        class="relative z-0 md:h-[500px] h-[268px] object-center object-cover w-full"
                    />
                </figure>
            </swiper-slide>
        </swiper>
    </div>
</template>

<style lang="scss">
.second-section {
    .swiper-3d {
        .swiper-slide-shadow-left {
            background-image: linear-gradient(
                to left,
                rgba(255, 255, 255, 0.5),
                rgba(255, 255, 255, 0)
            );
        }

        .swiper-slide-shadow-right {
            background-image: linear-gradient(
                to right,
                rgba(255, 255, 255, 0.5),
                rgba(255, 255, 255, 0)
            );
        }
    }

    .swiper {
        position: relative;
    }

    .swiper-button-prev,
    .swiper-button-next {
        top: 30px;
        bottom: 50px;
        height: auto;
        width: calc((100% - 1200px) / 2);
        opacity: 0;

        @media (max-width: 1440px) {
            width: calc((100% - 0px) / 2);
        }

        @media (max-width: 1280px) {
            width: calc((100% - 900px) / 2);
        }

        @media (max-width: 1024px) {
            width: calc((100% - 650px) / 2);
        }

        @media (max-width: 767px) {
            display: none;
        }
    }

    .swiper-pagination {
        position: relative;
        margin-top: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 28px;

        @media (max-width: 767px) {
            column-gap: 18px;
            margin-top: 26px;
        }

        .swiper-pagination-bullet {
            display: block;
            width: 12px;
            height: 12px;
            background-color: #5fd0df;
            margin: 0;
            opacity: 1;

            @media (max-width: 767px) {
                width: 8px;
                height: 8px;
            }

            &.swiper-pagination-bullet-active {
                background-color: var(--dark-blue-color);
            }
        }
    }
}
</style>
