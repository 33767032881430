﻿<!--
 © Copyright 2024 webzaytsev.ru.
 @author Nikita Zaytsev <nikita@webzaytsev.ru>
-->
<script setup lang="ts">
import { ref } from 'vue';

import AppLink from '@/components/AppLink.vue';
import TgQRCode from '@/components/images/TgQRCode.vue';
import IconMail from '@/components/icons/IconMail.vue';
import IconPhone from '@/components/icons/IconPhone.vue';
import IconLocationFooter from '@/components/icons/IconLocationFooter.vue';
import DogPaw from '@/components/images/DogPaw.vue';
import FooterForm from '@/components/forms/FooterForm.vue';

const aboutBrandLinks = [
    {
        link: '/news',
        text: 'Новости',
    },
    // {
    //     link: '/faq',
    //     text: 'Вопрос ответ',
    // },
    // {
    //     link: '#',
    //     text: 'Амбассадоры',
    // },
    // {
    //     link: '#',
    //     text: 'Обучение',
    // },
    // {
    //     link: '#',
    //     text: 'Партнерам',
    // },
    // {
    //     link: '#',
    //     text: '#Obritитeдруга',
    // },
];
const forOwnerLinks = [
    {
        link: '/catalog',
        text: 'Каталог',
    },
    {
        link: '/catalog?animal_id=1',
        text: 'Корм для собак',
    },
    {
        link: '/catalog?animal_id=2',
        text: 'Корма для кошек',
    },
    {
        link: '/where-to-buy',
        text: 'Где купить?',
    },
    {
        link: '/presentation',
        text: 'Линейки',
    },
];

function clearPhoneNumber(number: string): string {
    return number.replace(/\D/g, '');
}
</script>

<template>
    <footer
        class="px-5 w-full overflow-x-hidden app-footer"
        :class="$route.name === 'home' ? 'bg-white' : 'bg-turquoise-200'"
    >
        <div class="max-w-footer mx-auto lg:pt-34 pt-12.5 md:pb-11 pb-9.5 relative">
            <DogPaw
                color="#E0F2F2"
                class="absolute z-10 md:top-5.5 top-60 md:-right-14.5 -right-10 left-auto block rotate-[43.4deg] md:w-auto w-[127px] h-auto"
                data-speed="0.9"
            />
            <DogPaw
                color="#E0F2F2"
                class="absolute z-10 top-8.5 -left-21 -rotate-[24deg] md:block hidden w-[112px] h-auto"
                data-speed="1.1"
            />
            <div
                class="relative z-20 md:pb-10 pb-19.5 border-b border-blue grid md:grid-cols-2 gap-y-16 md:px-0 px-4.5"
            >
                <div class="grid grid-cols-2">
                    <div class="">
                        <p
                            class="font-gill md:mb-5 mb-6 md:text-30 text-24 md:-tracking-0.6 -tracking-0.5 md:pl-1 md:leading-1.5 leading-1.9"
                        >
                            О бренде
                        </p>
                        <nav>
                            <ul class="grid gap-y-2.5">
                                <li
                                    class=""
                                    v-for="aboutBrandLink of aboutBrandLinks"
                                    :key="aboutBrandLink.text"
                                >
                                    <AppLink
                                        :text="aboutBrandLink.text"
                                        :to="aboutBrandLink.link"
                                    />
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div class="">
                        <p
                            class="font-gill md:mb-5 mb-6 md:text-30 text-24 md:-tracking-0.6 -tracking-0.5 md:pl-[1px] md:leading-1.5 leading-1.9"
                        >
                            Владельцам
                        </p>
                        <nav>
                            <ul class="grid gap-y-2.5">
                                <li
                                    class=""
                                    v-for="forOwnerLink of forOwnerLinks"
                                    :key="forOwnerLink.text"
                                >
                                    <AppLink :text="forOwnerLink.text" :to="forOwnerLink.link" />
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div class="lg:pl-15.5 md:pl-10">
                    <p
                        class="font-gill md:mb-9.5 mb-5 md:text-30 text-24 md:-tracking-0.6 -tracking-0.5 md:pl-7.5 md:leading-1.5 leading-1.9 md:text-left text-center"
                        v-html="'Следите за новостями'"
                    />
                    <FooterForm />
                </div>
            </div>
            <div class="relative z-20 pt-10 grid md:grid-cols-2 gap-y-16">
                <div
                    class="md:border-r border-blue md:pr-10 xl:block flex flex-col justify-between md:order-1 order-2"
                >
                    <div class="flex items-start gap-x-5 md:mb-6.5 mb-8.5">
                        <TgQRCode />
                        <p class="">
                            Сможем помочь вам <br />выбрать корм в Telegram <br />
                            <AppLink
                                text="@brit_support"
                                to="https://t.me/brit_premium"
                                target="_blank"
                                rel="noopener noreferrer"
                                :is-route="false"
                            />
                        </p>
                    </div>
                    <p class="text-12 leading-1.5 opacity-50 max-w-[563px]">
                        &copy;2009 - 2022 ООО КОНТИНЕНТЗОО Все права защищены. Любое использование
                        материалов сайта допускается только с письменного согласия правообладателя.
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <RouterLink
                            class="text-12 underline whitespace-nowrap md:inline block mx-auto mt-1 md:text-left text-center"
                            to="#"
                        >
                            Политика конфиденциальности
                        </RouterLink>
                    </p>
                </div>
                <div
                    class="xl:flex md:pl-10 md:pr-11 md:pb-0 pb-14 border-b md:border-transparent border-blue items-start justify-between w-full md:order-2 order-1"
                >
                    <div class="grid gap-y-[25px]">
                        <div class="flex items-start gap-x-5">
                            <IconMail />
                            <AppLink
                                text="info@brit-rus.ru"
                                to="https://mailto:info@brit-rus.ru"
                                :is-route="false"
                            />
                        </div>
                        <!--                        <div class="flex items-start gap-x-5">-->
                        <!--                            <IconPhone />-->
                        <!--                            <AppLink-->
                        <!--                                text="8 (800) 550 07 99"-->
                        <!--                                to="tel:88005500799"-->
                        <!--                                :is-route="false"-->
                        <!--                            />-->
                        <!--                        </div>-->
                        <div class="flex items-start gap-x-5">
                            <IconLocationFooter />
                            <p class="">
                                151251, Россия, Щелковский район, <br />д. Соколово, ул.
                                Промышленная, стр. 6
                            </p>
                        </div>
                    </div>
                    <!--                    <div class="xl:block flex xl:mt-0 mt-13.5 justify-between">-->
                    <!--                        <RouterLink to="#" class="flex items-center gap-x-2.5">-->
                    <!--                            <span>Вход для партнеров</span>-->
                    <!--                            <svg width="9" height="16" viewBox="0 0 9 16" fill="none">-->
                    <!--                                <path-->
                    <!--                                    d="M1.4375 14.6445L8.4375 7.64453L1.4375 0.644625"-->
                    <!--                                    class="stroke-blue"-->
                    <!--                                    stroke-linecap="round"-->
                    <!--                                    stroke-linejoin="round"-->
                    <!--                                />-->
                    <!--                            </svg>-->
                    <!--                        </RouterLink>-->
                    <!--                        <RouterLink to="#" class="flex items-center gap-x-2.5 xl:mt-6.5">-->
                    <!--                            <span>Сотрудничество</span>-->
                    <!--                            <svg width="9" height="16" viewBox="0 0 9 16" fill="none">-->
                    <!--                                <path-->
                    <!--                                    d="M1.4375 14.6445L8.4375 7.64453L1.4375 0.644625"-->
                    <!--                                    class="stroke-blue"-->
                    <!--                                    stroke-linecap="round"-->
                    <!--                                    stroke-linejoin="round"-->
                    <!--                                />-->
                    <!--                            </svg>-->
                    <!--                        </RouterLink>-->
                    <!--                    </div>-->
                </div>
            </div>
        </div>
    </footer>
</template>
