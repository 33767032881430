﻿<!--
 © Copyright 2024 webzaytsev.ru.
 @author Nikita Zaytsev <nikita@webzaytsev.ru>
-->
<script setup lang="ts">
import FeedImage from '@/components/home-page/quiz/FeedImage.vue';
import DogImage from '@/components/home-page/quiz/DogImage.vue';
import CatImage from '@/components/home-page/quiz/CatImage.vue';
import type { AnimalTypes } from '@/components/home-page/quiz/QuizTest.vue';
import { ref } from 'vue';

interface Props {
    stage: number;
    stagesCount: number;
    type: AnimalTypes;
}

const props = defineProps<Props>();
const progressLine = ref(null);

function getDogPosition(): number {
    if (!progressLine.value) return 0;

    const progressLineWidth = parseInt(window.getComputedStyle(progressLine.value).width) - 120;
    const percent = (100 / props.stagesCount) * props.stage;
    return (progressLineWidth * percent) / 100;
}
</script>

<template>
    <div class="h-[61px] w-full relative" ref="progressLine">
        <div
            class="absolute w-full h-2.5 bg-turquoise-600 rounded-full left-0 top-auto bottom-0.5 z-10"
        />
        <FeedImage class="absolute z-20 bottom-0 top-auto left-auto -right-1" />
        <p class="absolute z-20 text-46 leading-1.2 font-gill left-1 -bottom-3.5" v-if="!type">?</p>
        <DogImage
            v-else-if="type === 'dog'"
            class="absolute z-20 bottom-0.5 transition-all duration-300"
            :offset="getDogPosition()"
        />
        <CatImage
            v-else-if="type === 'cat'"
            class="absolute z-20 bottom-0.5 transition-all duration-300"
            :offset="getDogPosition()"
        />
    </div>
</template>

<style scoped lang="scss"></style>
