﻿<!--
 © Copyright 2024 webzaytsev.ru.
 @author Nikita Zaytsev <nikita@webzaytsev.ru>
-->
<script setup lang="ts">
import ozon from '@/assets/images/ozon-2x.png';
import whiteRabbit from '@/assets/images/white-rabbit-2x.png';
import fourPaws from '@/assets/images/four-paw-2x.png';
import wb from '@/assets/images/wb-2x.png';
import onlru from '@/assets/images/onlru-2x.png';
import YMarket from '@/assets/images/y-market-2x.png';
import petshop from '@/assets/images/petshop-2x.png';
import { ref, watchEffect } from 'vue';

interface Image {
    url: string;
    width: number;
    height: number;
    link: string;
}

const loading = ref(true);
const activeIndex = ref(3);
const rangeRef = ref(null);
const touchStartX = ref<number | null>(null);
const touchEndX = ref<number | null>(null);
const isSwiping = ref(false);

const images = ref<Image[]>([
    {
        url: fourPaws,
        width: 0,
        height: 0,
        link: 'https://4lapy.ru/brand/brit/',
    },
    {
        url: onlru,
        width: 0,
        height: 0,
        link: 'https://www.onlinetrade.ru/sitesearch.html?query=brit&cat_id=0&archive=0&brand=BRIT&force_items=1',
    },
    {
        url: YMarket,
        width: 0,
        height: 0,
        link: '',
    },
    {
        url: ozon,
        width: 0,
        height: 0,
        link: 'https://www.ozon.ru/brand/brit-18090538/',
    },
    {
        url: petshop,
        width: 0,
        height: 0,
        link: 'https://www.petshop.ru/brand/brit/',
    },
    {
        url: wb,
        width: 0,
        height: 0,
        link: 'https://www.wildberries.ru/catalog/0/search.aspx?search=brit&xsearch=true',
    },
    {
        url: whiteRabbit,
        width: 0,
        height: 0,
        link: 'https://petsmart.ru/brand/brit-premium/',
    },
]);

watchEffect(async () => {
    await Promise.all<void>(
        images.value.map(async (img: Image) => {
            const image = new Image();
            image.src = img.url;

            await new Promise<void>((resolve) => {
                image.onload = function () {
                    img.width = image.width / 2;
                    img.height = image.height / 2;
                    resolve();
                };
            });
        }),
    );

    loading.value = false;
});

//@ts-ignore
const getProgress = (value, min, max) => {
    return ((value - min) / (max - min)) * 100;
};

watchEffect(() => {
    if (rangeRef.value) {
        //@ts-ignore
        const progress = getProgress(activeIndex.value, rangeRef.value.min, rangeRef.value.max);
        //@ts-ignore
        rangeRef.value.style.setProperty('--ProgressPercent', `${progress}%`);
    }
});

function clickHandler(idx: number) {
    if (activeIndex.value !== idx) {
        activeIndex.value = idx;
    }
}

//@ts-ignore
function handleTouchStart(e) {
    touchStartX.value = e.touches[0].clientX;
    isSwiping.value = true;
}

//@ts-ignore
function handleTouchMove(e) {
    touchEndX.value = e.touches[0].clientX;
}

function handleTouchEnd() {
    if (!isSwiping.value || touchEndX.value === null) return;
    //@ts-ignore
    const deltaX = touchEndX.value - touchStartX.value;

    if (Math.abs(deltaX) > 150) {
        if (deltaX > 0) {
            if (activeIndex.value + 2 === images.value.length) {
                activeIndex.value = 0;
            } else if (activeIndex.value + 2 === images.value.length + 1) {
                activeIndex.value = 1;
            } else {
                activeIndex.value = activeIndex.value + 2;
            }
        } else if (deltaX < 0) {
            if (activeIndex.value === 0) {
                activeIndex.value = images.value.length - 2;
            } else if (activeIndex.value === 1) {
                activeIndex.value = images.value.length - 1;
            } else {
                activeIndex.value = activeIndex.value - 2;
            }
        }
    } else if (Math.abs(deltaX) > 50) {
        if (deltaX > 0) {
            if (activeIndex.value + 1 === images.value.length) {
                activeIndex.value = 0;
            } else {
                activeIndex.value++;
            }
        } else if (deltaX < 0) {
            if (activeIndex.value === 0) {
                activeIndex.value = images.value.length - 1;
            } else {
                activeIndex.value--;
            }
        }
    }

    isSwiping.value = false;
}
</script>

<template>
    <section class="md:pt-21 md:pb-25.5 pb-7 bg-turquoise-200 px-5 overflow-hidden" v-if="!loading">
        <h2 class="mb-6 font-gill xl:text-60 md:text-48 text-24 text-center font-bold">
            Где купить?
        </h2>
        <div
            class="md:max-w-[1512px] md:w-full w-[140vw] 2xl:h-[520px] md:h-[36.11vw] h-[50vw] mx-auto relative z-10 md:left-auto -left-[calc(20vw_+_20px)]"
            @touchstart="handleTouchStart"
            @touchmove="handleTouchMove"
            @touchend="handleTouchEnd"
        >
            <a
                :href="img.link"
                target="_blank"
                class="rounded-full items-center justify-center flex absolute transition-all duration-500 w-[35.05%] h-[38.07%] origin-top-left cursor-pointer"
                v-for="(img, idx) in images"
                :key="idx"
                @click="clickHandler(idx)"
                :class="{
                    'top-full -translate-y-full left-1/2 -translate-x-1/2 z-50 bg-turquoise-500':
                        idx === activeIndex,
                    'scale-[0.77] top-[45.77%] left-[8.99%] bg-[#CBECEC] z-40':
                        idx - activeIndex === 1 || idx - activeIndex === (images.length - 1) * -1,
                    'scale-[0.77] top-[45.77%] left-[64.02%] bg-[#CBECEC] z-40':
                        idx - activeIndex === -1 || idx - activeIndex === images.length - 1,
                    'scale-[0.65] left-0 top-[20%] bg-[#D1EDED] z-30':
                        idx - activeIndex === 2 || idx - activeIndex === (images.length - 2) * -1,
                    'scale-[0.65] top-[20%] left-[77.19%] bg-[#D1EDED] z-30':
                        idx - activeIndex === -2 || idx - activeIndex === images.length - 2,
                    'scale-[0.53] top-[8.85%] left-[20.23%] bg-[#DAF1F1] z-10':
                        idx - activeIndex === 3 || idx - activeIndex === (images.length - 3) * -1,
                    'scale-[0.53] top-[8.85%] left-[60.84%] bg-[#DAF1F1] z-10':
                        idx - activeIndex === -3 || idx - activeIndex === images.length - 3,
                }"
            >
                <img
                    :src="img.url"
                    alt="img"
                    :width="img.width"
                    :height="img.height"
                    class="object-contain select-none max-w-[90%] max-h-[90%] mx-auto"
                />
                <svg
                    viewBox="0 0 442 60"
                    fill="none"
                    class="w-[75.61%] left-1/2 -translate-x-1/2 block absolute h-auto top-auto -bottom-[18.18%]"
                >
                    <g opacity="0.5" filter="url(#filter0_f_1_1987)">
                        <ellipse
                            cx="221"
                            cy="30"
                            rx="200"
                            ry="9"
                            fill="url(#paint0_radial_1_1987)"
                            fill-opacity="0.6"
                        />
                    </g>
                    <defs>
                        <filter
                            id="filter0_f_1_1987"
                            x="0"
                            y="0"
                            width="442"
                            height="60"
                            filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB"
                        >
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="BackgroundImageFix"
                                result="shape"
                            />
                            <feGaussianBlur
                                stdDeviation="10.5"
                                result="effect1_foregroundBlur_1_1987"
                            />
                        </filter>
                        <radialGradient
                            id="paint0_radial_1_1987"
                            cx="0"
                            cy="0"
                            r="1"
                            gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(221 29.9998) scale(184.5 4100)"
                        >
                            <stop stop-color="#788A8A" />
                            <stop offset="1" stop-color="#788A8A" stop-opacity="0" />
                        </radialGradient>
                    </defs>
                </svg>
            </a>
            <!--            <div-->
            <!--                class="bg-[#F7FCFC] left-0 right-0 h-[37.88%] top-0 blur-[60] absolute z-20 opacity-30"-->
            <!--            />-->
        </div>
        <input
            class="relative z-20"
            ref="rangeRef"
            type="range"
            :step="1"
            :min="0"
            :max="images.length - 1"
            v-model.number="activeIndex"
        />
    </section>
</template>

<style scoped lang="scss">
input[type='range'] {
    display: block;
    width: 70%;
    margin: 40px auto 0;
    position: relative;
    appearance: none;
    /* pointer-events: none; */
    border-radius: 100%;
}

/* ::before element to replace the slider track */
input[type='range']::before {
    content: '';
    position: absolute;
    width: var(--ProgressPercent, 100%);
    height: 100%;
    background: #8bd1d9;
    /* z-index: -1; */
    pointer-events: none;
    border-radius: 999px;
}

/* `::-webkit-slider-runnable-track` targets the track (background) of a range slider in chrome and safari browsers. */
input[type='range']::-webkit-slider-runnable-track {
    appearance: none;
    background: #e0f2f2;
    height: 0.5rem;
    border-radius: 999px;
}

/* `::-moz-range-track` targets the track (background) of a range slider in Mozilla Firefox. */
input[type='range']::-moz-range-track {
    appearance: none;
    background: #e0f2f2;
    height: 0.5rem;
    border-radius: 999px;
}

input[type='range']::-webkit-slider-thumb {
    position: relative;
    top: 50%;
    transform: translate(0, -50%);
    width: 25px;
    height: 25px;
    background: #fff;
    border-radius: 100%;
    pointer-events: all;
    appearance: none;
    border: 3px solid #8bd1d9;
    cursor: pointer;
    z-index: 1;
}
input[type='range']::-webkit-slider-thumb {
    position: relative;
    top: 50%;
    transform: translate(0, -50%);
    /* ... */
}
</style>
