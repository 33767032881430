﻿<!--
 © Copyright 2024 webzaytsev.ru.
 @author Nikita Zaytsev <nikita@webzaytsev.ru>
-->
<template>
    <svg
        style="
            shape-rendering: geometricPrecision;
            text-rendering: geometricPrecision;
            image-rendering: optimizeQuality;
            fill-rule: evenodd;
            clip-rule: evenodd;
        "
        viewBox="0 0 9162.4 2530.38"
    >
        <g id="Слой_x0020_1">
            <metadata id="CorelCorpID_0Corel-Layer" />
            <g id="_1672018965664">
                <path
                    class="fil0"
                    d="M7647.78 307.02c0,160.19 -130.87,291.28 -291.1,291.28 -160.13,0 -291.13,-131.09 -291.13,-291.28 0,-160.12 131,-291.13 291.13,-291.13 160.23,0 291.1,131.01 291.1,291.13z"
                />
                <path
                    class="fil0"
                    d="M3911.9 1453.81l433.31 0c218.37,0 378.54,98.42 378.54,280.4 0,214.67 -119.99,305.67 -462.19,305.67l-349.66 0 0 -586.07zm-618.76 -1441.54l0 2482.68 1208.45 0c724.62,0 877.4,-444.18 877.4,-727.96 0,-291.33 -174.66,-546.13 -527.79,-637.24 207.62,-101.89 338.41,-287.43 338.41,-495.01 0,-276.51 -236.52,-622.47 -822.51,-622.47l-1073.96 0zm618.76 455.12l323.87 0c226.05,0 316.88,134.62 316.88,262.07 0,130.91 -90.83,269.38 -316.88,269.38l-323.87 0 0 -531.45z"
                />
                <path
                    class="fil0"
                    d="M3911.9 1453.81l433.31 0c218.37,0 378.54,98.42 378.54,280.4 0,214.67 -119.99,305.67 -462.19,305.67l-349.66 0 0 -586.07zm-618.76 -1441.54l0 2482.68 1208.45 0c724.62,0 877.4,-444.18 877.4,-727.96 0,-291.33 -174.66,-546.13 -527.79,-637.24 207.62,-101.89 338.41,-287.43 338.41,-495.01 0,-276.51 -236.52,-622.47 -822.51,-622.47l-1073.96 0zm618.76 455.12l323.87 0c226.05,0 316.88,134.62 316.88,262.07 0,130.91 -90.83,269.38 -316.88,269.38l-323.87 0 0 -531.45z"
                />
                <path
                    class="fil0"
                    d="M6064.62 1297.3l7.32 0c156.47,-349.39 331.05,-513.17 535.06,-513.17 105.73,0 196.57,39.91 349.52,152.77l-145.64 465.94c-138.37,-87.4 -229.34,-123.74 -313.04,-123.74 -192.86,0 -316.74,182.11 -433.22,491.45l0 724.4 -527.87 0 0 -1678.23 527.87 0 0 480.58z"
                />
                <path
                    class="fil0"
                    d="M6064.62 1297.3l7.32 0c156.47,-349.39 331.05,-513.17 535.06,-513.17 105.73,0 196.57,39.91 349.52,152.77l-145.64 465.94c-138.37,-87.4 -229.34,-123.74 -313.04,-123.74 -192.86,0 -316.74,182.11 -433.22,491.45l0 724.4 -527.87 0 0 -1678.23 527.87 0 0 480.58z"
                />
                <polygon
                    class="fil0"
                    points="7091.28,2495 7619.03,2495 7619.03,816.72 7091.28,816.72 "
                />
                <polygon
                    class="fil0"
                    points="7091.28,2495 7619.03,2495 7619.03,816.72 7091.28,816.72 "
                />
                <path
                    class="fil0"
                    d="M9162.41 1177.11l-611.31 0 0 691.68c0,163.64 94.58,269.38 258.36,269.38 109.03,0 211.15,-25.46 352.95,-98.29l0 382.12c-185.54,76.56 -323.78,105.68 -487.71,105.68 -349.37,0 -651.67,-160.17 -651.67,-629.72l0 -720.85 -240.07 0 0 -101.85 702.58 -786.29 65.56 0 0 527.75 611.31 0 0 360.39z"
                />
                <path
                    class="fil0"
                    d="M9162.41 1177.11l-611.31 0 0 691.68c0,163.64 94.58,269.38 258.36,269.38 109.03,0 211.15,-25.46 352.95,-98.29l0 382.12c-185.54,76.56 -323.78,105.68 -487.71,105.68 -349.37,0 -651.67,-160.17 -651.67,-629.72l0 -720.85 -240.07 0 0 -101.85 702.58 -786.29 65.56 0 0 527.75 611.31 0 0 360.39z"
                />
                <path
                    class="fil1"
                    d="M200.62 181.01c-45.26,44.31 -67.6,101.81 -67.7,159.73 0.1,57.19 21.8,113.95 66.04,158.12l2.82 3.07 436.1 508.88 370.28 -415.92 1.2 -1.31 88.41 -92.67c30.16,-43.78 48.04,-101.82 47.94,-159.11 -0.14,-63.89 -21.27,-125.16 -66.82,-170.6 -44.84,-44.63 -103.68,-66.96 -163.05,-67.03 -58.63,0.07 -116.7,21.84 -161.4,65.55l-85.62 83.54c-20.39,19.86 -52.98,19.68 -73.13,-0.36l-70.62 -70.31c-44.91,-44.69 -103.76,-66.99 -163.13,-67.02 -58.56,0.03 -116.59,21.76 -161.32,65.44zm435.99 961.1c-15.06,-0.14 -29.24,-6.73 -39.05,-18.2l-473.64 -552.73c-63.32,-63.85 -95.25,-147.36 -95.18,-230.44 -0.07,-84.87 33.31,-170.1 99.06,-234.24 64.88,-63.46 149.79,-95.18 234.14,-95.14 85.48,0 171.49,32.52 236.65,97.36l34.21 34.08 48.83 -47.62c64.84,-63.5 149.86,-95.25 234.21,-95.18 85.48,-0.04 171.48,32.49 236.61,97.43 67.98,67.77 97.54,157.73 97.43,244.37 -0.14,80.08 -24.37,158.86 -69.25,222.39l-4.83 5.82 -90.31 94.69 -409.43 459.95c-9.92,11.15 -24.03,17.46 -38.92,17.46l-0.53 0z"
                />
                <path
                    fill="#8ad3df"
                    d="M265.67 1803.22c5.36,12.59 5.78,26.95 1.09,39.83 -16.65,46.32 -25.86,96.27 -25.86,148.66 0.18,41.03 15.7,74.08 43.01,111.65 27.06,37.19 65.4,74.97 100.36,120.05 43.39,56.45 83.79,107.92 127.21,142.81 43.85,34.92 87.6,54.85 150.43,55.24 60.85,-0.46 100.89,-18.06 141.78,-49.24 40.43,-31.26 78.95,-78.29 122.8,-131.41 37.57,-45.09 78.85,-85.62 108.66,-126.37 30.02,-41.13 47.59,-79.06 47.62,-122.73 0,-42.97 -5.89,-85.34 -16.75,-125.8 -3.14,-11.68 -2.23,-24.31 2.5,-35.46l121.39 -285.29 -230.4 92.11c-16.93,6.74 -35.91,4.66 -50.97,-5.57 -68.76,-47.1 -155.37,-69.82 -246.63,-69.82 -84.35,0.04 -162.46,25.3 -228.5,68.97 -14.85,9.85 -33.65,11.75 -50.23,5.12l-227.37 -90.88 109.86 258.13zm396.24 727.15c-88.94,0.45 -162.07,-32.92 -218.9,-79.49 -57.26,-46.53 -101.39,-104.7 -144.99,-160.97 -28.51,-37.29 -67.49,-75.53 -101.88,-122.06 -34.15,-46.18 -64.24,-104.39 -64.14,-176.14 0,-57.4 8.89,-112.85 25.37,-164.89l-153 -359.52c-8.65,-20.28 -4.24,-43.67 11.14,-59.4 15.42,-15.74 38.7,-20.68 59.17,-12.53l323.39 129.26c77.57,-45.44 167.85,-71.61 263.84,-71.61 99.06,0 197.8,22.22 283.03,72.81l326.15 -130.46c20.42,-8.18 43.74,-3.21 59.16,12.53 15.38,15.73 19.79,39.12 11.14,59.4l-165.48 389.01c10.23,43.64 15.8,89.11 15.8,135.4 0,75.25 -31.89,137.16 -68.83,187.15 -37.14,50.41 -80.29,92.32 -112.57,131.48 -43.35,52.03 -85.23,105.2 -139.77,147.85 -53.73,42.37 -122.62,72.18 -206.66,72.18l-1.97 0z"
                />
                <path
                    class="fil3"
                    d="M1975.55 2527.68c-57.43,-0.07 -110.32,-18.48 -150.64,-50.72 -40.14,-31.97 -68.44,-80.33 -68.4,-135.37l0 -109.53 -109.58 0c-55.1,0.03 -103.43,-28.3 -135.36,-68.44 -32.2,-40.36 -50.55,-93.31 -50.65,-150.67l0 -104.71c0.1,-57.36 18.45,-110.24 50.65,-150.6 31.93,-40.14 80.26,-68.4 135.36,-68.37l109.58 0 0 -109.57c-0.04,-55.14 28.26,-103.47 68.4,-135.4 40.36,-32.2 93.24,-50.58 150.64,-50.65l104.7 0c57.43,0.07 110.32,18.45 150.64,50.65 40.14,31.93 68.47,80.26 68.4,135.4l0 109.57 109.54 0 0 104.18 -213.71 0 0 -213.75c-0.07,-18.91 -9.14,-37.54 -29.43,-54.08 -20.07,-16.23 -50.62,-27.8 -85.44,-27.76l-104.7 0c-34.79,-0.04 -65.34,11.53 -85.41,27.79 -20.28,16.51 -29.39,35.14 -29.42,54.05l0 213.75 -213.79 0c-18.9,0.07 -37.5,9.14 -54.04,29.42 -16.19,20.07 -27.8,50.59 -27.76,85.37l0 104.71c-0.04,34.82 11.57,65.4 27.76,85.47 16.54,20.32 35.14,29.36 54.04,29.43l213.79 0 0 213.74c0.03,18.84 9.14,37.54 29.42,54.09 20.07,16.22 50.66,27.83 85.41,27.76l104.7 0c34.79,0.07 65.3,-11.54 85.41,-27.76 20.32,-16.55 29.39,-35.25 29.46,-54.09l0 -213.74 213.71 0c18.91,-0.07 37.57,-9.11 54.12,-29.43 16.22,-20.07 27.83,-50.65 27.76,-85.47l0 -104.71c0.07,-34.78 -11.54,-65.3 -27.76,-85.37 -16.55,-20.28 -35.18,-29.35 -54.12,-29.42l0 -104.18c55.14,-0.03 103.47,28.23 135.4,68.37 32.24,40.36 50.62,93.24 50.69,150.6l0 104.71c-0.07,57.39 -18.45,110.31 -50.69,150.7 -31.93,40.11 -80.26,68.44 -135.4,68.41l-109.54 -0.04 0 109.57c0.07,55.04 -28.22,103.4 -68.37,135.37 -40.35,32.24 -93.24,50.65 -150.67,50.72l-104.7 0z"
                />
                <path
                    class="fil4"
                    d="M1704.09 220.41c-20.36,-20.35 -20.36,-53.37 0,-73.73 46.28,-46.35 91.79,-81.17 144.7,-103.29 52.85,-22.16 110.6,-30.55 178.69,-30.48 28.75,0 52.1,23.32 52.1,52.07 0,28.78 -23.35,52.14 -52.1,52.14 -60.12,0.03 -101.96,7.16 -138.43,22.4 -36.48,15.31 -70.17,39.79 -111.27,80.86 -10.16,10.19 -23.53,15.27 -36.86,15.27 -13.34,0 -26.67,-5.08 -36.83,-15.24z"
                />
                <path
                    class="fil4"
                    d="M1851.09 1065.88c-44.91,-44.59 -76.66,-101.57 -100.65,-161.15 -47.48,-119.52 -64.03,-250.08 -64.28,-325.97 0,-28.75 23.29,-52.07 52.07,-52.07 28.79,0 52.11,23.32 52.11,52.07 -0.11,45.55 8.64,127.5 30.62,208.04 21.56,80.85 57.64,160.51 103.36,205 30.84,29.84 62.09,45.12 103.12,45.47 28.79,0 52.11,23.32 52.11,52.1 0,28.76 -23.32,52.07 -52.11,52.07l-1.13 0.04c-70.52,-0.04 -131.02,-31.33 -175.22,-75.6z"
                />
                <path
                    class="fil4"
                    d="M1373 1078.26c-30.76,-37 -43.88,-87.24 -43.92,-149.12 0.11,-71.96 17.57,-162.91 56.87,-282.01 32.7,-99.66 37.04,-163.23 37.18,-215.93 0.04,-23.46 -1.02,-45.16 -1.06,-67.49 -0.56,-45.76 6.32,-98.35 40.54,-151.38 23.18,-36.79 51.61,-67.55 84.24,-90.1 32.53,-22.33 69.92,-36.76 109.75,-36.83 31.4,-0.14 63.71,9.63 91.23,29.07 23.57,16.48 29.28,49 12.77,72.57 -16.51,23.56 -48.97,29.28 -72.57,12.73 -10.9,-7.4 -20.25,-10.01 -31.43,-10.19 -13.86,-0.04 -31.57,5.33 -50.66,18.48 -19.01,13.06 -38.63,33.52 -55.14,59.77 -21.73,35.84 -23.95,56.23 -24.55,95.88 0,18.7 1.09,40.99 1.09,67.49 0.11,59.72 -6.1,139.03 -42.47,248.6 -37.29,112.46 -51.72,194.77 -51.58,249.34 -0.07,47.63 10.69,71.93 19.55,82.13 8.82,9.91 17.88,14.32 38.91,14.89 34.5,0.84 92.07,-22.02 140.51,-56.34 48.82,-33.27 89.75,-79.17 99.77,-104.11 3.03,-6.84 3.45,-11.11 3.38,-11.11 0,-28.79 23.32,-52.1 52.07,-52.1 28.79,0 52.11,23.31 52.11,52.1 -0.85,39.09 -18.14,69.32 -39.58,99.03 -21.95,29.45 -50.59,57.57 -83.12,83.11 -65.62,50.38 -144.46,92.43 -225.14,93.63l-2.33 0.03c-41.13,0 -86.25,-15.56 -116.42,-52.14z"
                />
                <path
                    class="fil4"
                    d="M2285.67 220.38c-41.09,-41.07 -74.82,-65.55 -111.26,-80.86 -36.48,-15.24 -78.32,-22.37 -138.4,-22.4 -28.82,0 -52.14,-23.36 -52.14,-52.11 0,-28.82 23.32,-52.14 52.14,-52.14 68.05,-0.03 125.77,8.36 178.65,30.52 52.88,22.12 98.39,56.94 144.71,103.29l0 0.04c20.32,20.32 20.32,53.37 -0.04,73.69 -10.16,10.16 -23.49,15.24 -36.79,15.24 -13.37,-0.03 -26.71,-5.11 -36.87,-15.27z"
                />
                <path
                    class="fil4"
                    d="M1983.87 1089.37c0,-28.78 23.36,-52.1 52.14,-52.1 41.03,-0.35 72.25,-15.63 103.09,-45.47 30.3,-29.81 56.86,-74.83 77.15,-125.8 41.1,-101.57 57.15,-226.31 56.87,-287.24 0,-28.75 23.31,-52.07 52.1,-52.07 28.75,0 52.07,23.32 52.07,52.07 -0.07,57.05 -9.67,144.36 -34.22,235.31 -24.94,90.62 -63.6,184.75 -130.7,251.81 -44.45,44.52 -105.38,75.92 -176.36,75.56l0 0.04c-28.78,0 -52.14,-23.32 -52.14,-52.11z"
                />
                <path
                    class="fil4"
                    d="M2571.67 1130.37c-60.78,-0.53 -119.7,-25.19 -173.67,-57.82 -53.76,-33.13 -101.64,-74.9 -134.59,-118.92 -21.44,-29.71 -38.73,-59.94 -39.58,-99.03l0.04 0c0,-28.79 23.32,-52.1 52.07,-52.1 28.78,0 52.1,23.31 52.1,52.1 -0.07,0.04 0.32,4.3 3.39,11.11 2.96,6.81 8.25,15.88 15.63,25.72 14.67,19.83 37.39,42.69 63.78,63.32 52.18,41.91 121.21,72.64 160.83,71.41 21.03,-0.57 30.06,-4.98 38.91,-14.89 8.82,-10.16 19.62,-34.5 19.55,-82.13 0.1,-54.57 -14.36,-136.88 -51.58,-249.38 -36.37,-109.53 -42.62,-188.84 -42.47,-248.6 0,-26.39 1.05,-48.68 1.02,-67.41 -0.6,-39.66 -2.79,-60.15 -24.52,-95.92 -16.44,-26.25 -36.12,-46.75 -55.14,-59.77 -19.05,-13.15 -36.76,-18.52 -50.62,-18.48 -11.22,0.18 -20.57,2.75 -31.36,10.19 -23.6,16.55 -56.1,10.87 -72.64,-12.7 -16.51,-23.56 -10.8,-56.05 12.77,-72.6 27.48,-19.44 59.83,-29.21 91.23,-29.07 39.83,0.04 77.26,14.47 109.75,36.83 32.63,22.55 61.03,53.31 84.21,90.1 34.21,53.06 41.09,105.66 40.49,151.42 0,22.26 -1.02,43.88 -0.98,67.41 0.1,52.71 4.48,116.28 37.21,216.01 39.2,119.06 56.7,209.97 56.84,281.97 -0.07,61.84 -13.2,112.12 -43.96,149.12 -30.16,36.62 -75.32,52.14 -116.45,52.14l-2.26 -0.03z"
                />
            </g>
        </g>
    </svg>
</template>

<style scoped lang="scss">
.fil3 {
    fill: #8cc63f;
    fill-rule: nonzero;
}

.fil4 {
    fill: #be854c;
    fill-rule: nonzero;
}

.fil1 {
    fill: #ffc222;
    fill-rule: nonzero;
}

.fil0 {
    fill: #cfc9c4;
    fill-rule: nonzero;
}
</style>
