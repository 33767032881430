﻿<!--
 © Copyright 2024 webzaytsev.ru.
 @author Nikita Zaytsev <nikita@webzaytsev.ru>
-->
<script setup lang="ts">
import defaultCat from '@/components/home-page/quiz/images/cat.png';
import kitten from '@/components/home-page/quiz/images/kitten.png';
import sterilized from '@/components/home-page/quiz/images/sterilized.png';
import { onBeforeMount, ref } from 'vue';
import CatBg from '@/components/home-page/quiz/CatBg.vue';

const items = [
    { text: 'Котенок', img: { src: kitten, w: 179 } },
    { text: 'Взрослый', img: { src: defaultCat, w: 179 } },
    { text: 'Стерелизованный', img: { src: sterilized, w: 228 } },
];

const activeItem = ref();
const emit = defineEmits(['setCategory']);

function selectCategory(idx: number): void {
    activeItem.value = items[idx];
    emit('setCategory', items[idx].text);
}

onBeforeMount(() => selectCategory(0));
</script>

<template>
    <div class="h-full flex flex-col justify-end">
        <div
            v-for="({ text, img }, idx) in items"
            :key="idx"
            class="md:h-auto h-[calc(100%_-_45px)] relative z-10"
            :class="{
                hidden: text !== activeItem.text,
            }"
        >
            <div class="relative h-full">
                <img
                    :src="img.src"
                    :width="img.w"
                    alt="img"
                    class="h-auto max-h-full md:w-[unset] w-auto absolute left-1/2 -translate-x-1/2 top-auto bottom-0 z-20"
                />
                <CatBg
                    class="absolute rotate-[-15deg] top-auto -bottom-3 left-1/2 -translate-x-1/2 z-10"
                />
                <img
                    :src="items[0].img.src"
                    :width="items[0].img.w"
                    alt="img"
                    class="h-auto opacity-0"
                />
            </div>
        </div>

        <div class="flex items-center justify-center gap-1.5 md:my-5 my-2.5 relative z-20">
            <div
                class="rounded-full w-20 h-3 cursor-pointer"
                :class="{
                    'bg-turquoise-300': text !== activeItem.text,
                    'bg-turquoise-600': text === activeItem.text,
                }"
                v-for="({ text }, idx) in items"
                :key="idx"
                @click="selectCategory(idx)"
            />
        </div>
        <p class="text-center font-gill leading-1.2" v-html="activeItem.text" />
    </div>
</template>
