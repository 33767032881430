﻿/*
 © Copyright 2024 webzaytsev.ru.
 * @author Nikita Zaytsev <nikita@webzaytsev.ru>
*/
import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/',
            name: 'home',
            component: HomeView,
        },
        {
            path: '/catalog',
            name: 'catalog',
            component: () => import('@/views/CatalogView.vue'),
        },
        {
            path: '/catalog/category/:cat',
            name: 'catalog-category',
            component: () => import('@/views/CatalogView.vue'),
        },
        {
            path: '/catalog/page/:page',
            name: 'catalog-page',
            component: () => import('@/views/CatalogView.vue'),
        },
        {
            path: '/catalog/category/:cat/page/:page',
            name: 'catalog-category-page',
            component: () => import('@/views/CatalogView.vue'),
        },
        {
            path: '/product/:article',
            name: 'product',
            component: () => import('@/views/ProductView.vue'),
        },
        {
            path: '/where-to-buy',
            name: 'where-to-buy',
            component: () => import('@/views/WhereToBuyView.vue'),
        },
        {
            path: '/product-line',
            name: 'product-line',
            component: () => import('@/views/ProductLineView.vue'),
        },
        {
            path: '/presentation',
            name: 'presentation',
            component: () => import('@/views/PresentationView.vue'),
        },
        {
            path: '/about',
            name: 'about',
            component: () => import('@/views/AboutView.vue'),
        },
        {
            path: '/brit-care',
            name: 'brit-care',
            component: () => import('@/views/BritCareView.vue'),
        },
        {
            path: '/interesting',
            name: 'interesting',
            component: () => import('@/views/InterestingView.vue'),
        },
        {
            path: '/articles',
            name: 'articles',
            component: () => import('@/views/InterestingView.vue'),
        },
        {
            path: '/articles/:id',
            name: 'article',
            component: () => import('@/views/SingleArticleView.vue'),
        },
        {
            path: '/news',
            name: 'news',
            component: () => import('@/views/InterestingView.vue'),
        },
        {
            path: '/news/:id',
            name: 'news-single',
            component: () => import('@/views/SingleNewsView.vue'),
        },
        {
            path: '/events',
            name: 'events',
            component: () => import('@/views/InterestingView.vue'),
        },
        {
            path: '/events/:id',
            name: 'event',
            component: () => import('@/views/SingleEventView.vue'),
        },
        {
            path: '/contests',
            name: 'contests',
            component: () => import('@/views/InterestingView.vue'),
        },
        {
            path: '/contests/:id',
            name: 'contest',
            component: () => import('@/views/SingleContestView.vue'),
        },
        {
            path: '/for-pet-owners',
            name: 'for-pet-owners',
            component: () => import('@/views/ForPetOwnersView.vue'),
        },
        {
            path: '/for-breeders-&-kennels',
            name: 'for-breeders-&-kennels',
            component: () => import('@/views/ForBreeders&KennelsView.vue'),
        },
        {
            path: '/for-veterinary-clinics',
            name: 'for-veterinary-clinics',
            component: () => import('@/views/ForVetClinicsView.vue'),
        },
        // {
        //     path: '/ambassadors',
        //     name: 'ambassadors',
        //     component: () => import('@/views/AmbassadorsView.vue'),
        // },
        // {
        //     path: '/ambassador/:id',
        //     name: 'ambassador',
        //     component: () => import('@/views/AmbassadorView.vue'),
        // },
        {
            path: '/faq',
            name: 'faq',
            component: () => import('@/views/FAQView.vue'),
        },
        // {
        //     path: '/for-partners',
        //     name: 'for-partners',
        //     component: () => import('@/views/ForPartnersView.vue'),
        // },
        {
            path: '/:catchAll(.*)',
            redirect: '/404',
        },
        {
            path: '/404',
            component: () => import('@/views/NotFound.vue'),
        },
    ],
    scrollBehavior(to, from, next) {
        if (from.name === 'home') {
            resetFooterDogPawsPosition();
        }

        if (to.path.includes('catalog') && from.path.includes('catalog')) {
            return { top: 370 };
        }

        return { top: 0 };
    },
});

function resetFooterDogPawsPosition() {
    const appFooter = document.querySelector('footer.app-footer');

    if (!appFooter) return;

    const paws = [...appFooter.querySelectorAll('svg[data-speed]')];
    paws.forEach((paw) => paw.removeAttribute('style'));
}

export default router;
