﻿<!--
 © Copyright 2024 webzaytsev.ru.
 @author Nikita Zaytsev <nikita@webzaytsev.ru>
-->
<script setup lang="ts">
import hugeDog from '@/components/home-page/quiz/images/dog.png';
import middleDog from '@/components/home-page/quiz/images/middle-dog.png';
import smallDog from '@/components/home-page/quiz/images/small-dog.png';
import hugeYoungDog from '@/components/home-page/quiz/images/huge-yong-dog.png';
import mediumYoungDog from '@/components/home-page/quiz/images/medium-yong-dog.png';
import smallYoungDog from '@/components/home-page/quiz/images/small-yong-dog.png';
import defaultCat from '@/components/home-page/quiz/images/cat.png';
import kitten from '@/components/home-page/quiz/images/kitten.png';
import sterilized from '@/components/home-page/quiz/images/sterilized.png';
import type { Data } from '@/components/home-page/quiz/QuizTest.vue';
import { onMounted, ref } from 'vue';
import DogBg from '@/components/home-page/quiz/DogBg.vue';
import CatBg from '@/components/home-page/quiz/CatBg.vue';

const data = [
    {
        type: 'dog',
        size: 'Крупный',
        age: 'Старше 1 года',
        img: {
            src: hugeDog,
            w: 199,
        },
        url: '/catalog?animal_id=1&animal_age=more_year&size=big',
    },
    {
        type: 'dog',
        size: 'Крупный',
        age: 'До 1 года',
        img: {
            src: hugeYoungDog,
            w: 151,
        },
        url: '/catalog?animal_id=1&animal_age=less_year&size=big',
    },
    {
        type: 'dog',
        size: 'Средний',
        age: 'Старше 1 года',
        img: {
            src: middleDog,
            w: 184,
        },
        url: '/catalog?animal_id=1&animal_age=more_year&size=medium',
    },
    {
        type: 'dog',
        size: 'Средний',
        age: 'До 1 года',
        img: {
            src: mediumYoungDog,
            w: 162,
        },
        url: '/catalog?animal_id=1&animal_age=less_year&size=medium',
    },
    {
        type: 'dog',
        size: 'Маленький',
        age: 'Старше 1 года',
        img: {
            src: smallDog,
            w: 164,
        },
        url: '/catalog?animal_id=1&animal_age=less_year&size=small',
    },
    {
        type: 'dog',
        size: 'Маленький',
        age: 'До 1 года',
        img: {
            src: smallYoungDog,
            w: 145,
        },
        url: '/catalog?animal_id=1&animal_age=less_year&size=small',
    },
    {
        type: 'cat',
        category: 'Котенок',
        img: {
            src: kitten,
            w: 179,
        },
        url: '/catalog?animal_id=2&animal_age=less_year',
    },
    {
        type: 'cat',
        category: 'Взрослый',
        img: {
            src: defaultCat,
            w: 179,
        },
        url: '/catalog?animal_id=2&animal_age=more_year',
    },
    {
        type: 'cat',
        category: 'Стерелизованный',
        img: {
            src: sterilized,
            w: 288,
        },
        url: '/catalog?animal_id=2',
    },
];

interface Props {
    answers: Data;
}

const props = defineProps<Props>();

const activeItem = ref();

const emit = defineEmits(['goToCatalog']);
// https://brit.zaitsv.dev/catalog?animal_id=2&age=more_year
// https://brit.zaitsv.dev/catalog?animal_id=2&animal_age=more_year
function goToCatalog() {
    emit('goToCatalog');
}

onMounted(() => {
    activeItem.value = data.find((item) => {
        if (item.type === props.answers.type) {
            //@ts-ignore
            if (props.answers.data.category) {
                //@ts-ignore
                return props.answers.data.category === item.category;
            }

            //@ts-ignore
            return props.answers.data.size === item.size && props.answers.data.age === item.age;
        }

        return false;
    });
});
</script>

<template>
    <div class="grid md:grid-cols-2 grow mt-6.5 md:gap-0 gap-2" v-if="activeItem">
        <div class="relative flex items-end">
            <img
                :src="activeItem.img.src"
                :width="activeItem.img.w"
                alt="img"
                class="relative z-20 mx-auto h-auto"
            />
            <DogBg
                v-if="activeItem.type === 'dog'"
                class="absolute z-10 left-1/2 -translate-x-1/2 top-auto bottom-9"
            />
            <CatBg
                v-if="activeItem.type === 'cat'"
                class="absolute rotate-[-15deg] top-auto bottom-4 left-1/2 -translate-x-1/2 z-10"
            />
        </div>
        <div class="flex flex-col justify-between">
            <span />
            <p
                class="text-18 leading-1.2 font-gill text-blue md:max-w-[275px] md:text-left text-center md:mb-0 mb-4"
                v-html="`Мы подобрали для вас варианты, которые подойдут питомцу лучше всего.`"
            />
            <RouterLink
                :to="activeItem.url"
                class="block w-full rounded-full bg-turquoise-500 text-20 font-roboto leading-1.4 text-center md:py-4.5 py-3.5 border-2 border-turquoise-500 transition-all duration-300 hover:bg-transparent hover:border-blue"
                @click.prevent="goToCatalog"
            >
                Посмотреть результат
            </RouterLink>
        </div>
    </div>
</template>
