﻿<!--
 © Copyright 2024 webzaytsev.ru.
 @author Nikita Zaytsev <nikita@webzaytsev.ru>
-->
<script setup lang="ts">
import feedPackCat from '@/assets/images/catClose.png';
import feedPackOpenCat from '@/assets/images/catOpen.png';
import logoPrem from '@/assets/images/logo__brit-care.svg';
import IconSixSectionCat1 from '@/components/icons/IconSixSectionCat1.vue';
import IconSixSectionCat2 from '@/components/icons/IconSixSectionCat2.vue';
import IconSixSectionCat3 from '@/components/icons/IconSixSectionCat3.vue';
import IconSixSectionCat4 from '@/components/icons/IconSixSectionCat4.vue';
import IconSixSectionCat5 from '@/components/icons/IconSixSectionCat5.vue';
import IconSixSectionCat6 from '@/components/icons/IconSixSectionCat6.vue';
import SixSectionFish from '@/components/images/SixSectionFish.vue';
import Ham1 from '@/components/images/Ham1.vue';
import Ham2 from '@/components/images/Ham2.vue';
import Meat from '@/components/images/Meat.vue';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import MotionPathPlugin from 'gsap/MotionPathPlugin';
import { onMounted, ref } from 'vue';
import { start } from 'repl';

gsap.registerPlugin(ScrollTrigger, MotionPathPlugin);

const wrapCat = ref(null);

function animateIconCat() {
    if (window.matchMedia('(max-width: 1024px)').matches) return;
    gsap.set(['#iconcat1', '#iconcat2', '#iconcat3', '#iconcat4', '#iconcat5', '#iconcat6'], {
        scale: 0.2,
        opacity: 1,
    });
    gsap.timeline({
        scrollTrigger: {
            trigger: wrapCat.value,
            start: '-=0%',
            end: '+=850%',
            scrub: true,
        },
    })
        .to('#iconcat1', {
            scale: 0.9,
            ease: 'power1.inOut',
            motionPath: {
                path: '#path2',
                align: '#path2',
                alignOrigin: [0.5, 0.5],
                start: 1,
                end: 0,
            },
        })
        .to(
            '#iconcat2',
            {
                stagger: {
                    each: 0.2,
                    from: 'start',
                },
                scale: 0.9,
                ease: 'power1.inOut',
                motionPath: {
                    path: '#path2',
                    align: '#path2',
                    alignOrigin: [0.5, 0.5],
                    start: 1,
                    end: 0.125,
                },
            },
            0,
        )
        .to(
            '#iconcat3',
            {
                stagger: {
                    each: 0.4,
                    from: 'start',
                },
                scale: 0.9,
                ease: 'power1.inOut',
                motionPath: {
                    path: '#path2',
                    align: '#path2',
                    alignOrigin: [0.5, 0.5],
                    start: 1,
                    end: 0.26,
                },
            },
            0,
        )
        .to(
            '#iconcat4',
            {
                stagger: {
                    each: 0.6,
                    from: 'start',
                },
                scale: 0.9,
                ease: 'power1.inOut',
                motionPath: {
                    path: '#path2',
                    align: '#path2',
                    alignOrigin: [0.5, 0.5],
                    start: 1,
                    end: 0.41,
                },
            },
            0,
        )
        .to(
            '#iconcat5',
            {
                stagger: {
                    each: 0.8,
                    from: 'start',
                },
                scale: 0.9,
                ease: 'power1.inOut',
                motionPath: {
                    path: '#path2',
                    align: '#path2',
                    alignOrigin: [0.5, 0.5],
                    start: 1,
                    end: 0.55,
                },
            },
            0,
        )
        .to(
            '#iconcat6',
            {
                stagger: {
                    each: 1,
                    from: 'start',
                },
                scale: 0.9,
                ease: 'power1.inOut',
                motionPath: {
                    path: '#path2',
                    align: '#path2',
                    alignOrigin: [0.5, 0.5],
                    start: 1,
                    end: 0.67,
                },
            },
            0,
        )
        .to(
            '#iconcat1',
            {
                x: '223.438%',
                y: '70.5035%',
                alignOrigin: [0.5, 0.5],
                rotate: '180deg',
                scale: 0.8,
                ease: 'power1.inOut',
            },
            0.5,
        )
        .to(
            '#iconcat2',
            {
                x: '40.8471%',
                y: '83.1223%',
                alignOrigin: [0.5, 0.5],
                rotate: '180deg',
                scale: 0.8,
                ease: 'power1.inOut',
            },
            0.5,
        )
        .to(
            '#iconcat3',
            {
                x: '-85.212%',
                y: '5.396%',
                alignOrigin: [0.5, 0.5],
                rotate: '180deg',
                scale: 0.8,
                ease: 'power1.inOut',
            },
            0.5,
        )
        .to(
            '#iconcat4',
            {
                x: '-70.719%',
                y: '-104.231%',
                alignOrigin: [0.5, 0.5],
                rotate: '180deg',
                scale: 0.8,
                ease: 'power1.inOut',
            },
            0.5,
        )
        .to(
            '#iconcat5',
            {
                x: '-30.7713%',
                y: ' -73.378%',
                alignOrigin: [0.5, 0.5],
                rotate: '180deg',
                scale: 0.8,
                ease: 'power1.inOut',
            },
            0.5,
        )
        .to(
            '#iconcat6',
            {
                x: '-67.7975%',
                y: '-12.4941%',
                alignOrigin: [0.5, 0.5],
                rotate: '180deg',
                scale: 0.8,
                ease: 'power1.inOut',
            },
            0.5,
        )
        .to(
            '#rotateCat',
            {
                alignOrigin: [0.5, 0.5],
                rotate: '-180deg',
                y: '20%',
                ease: 'power1.inOut',
            },
            0.5,
        )

        .to(
            '#iconcat1',
            {
                y: '30.%',
                ease: 'power1.inOut',
                opacity: 0,
            },
            1,
        )
        .to(
            '#rotateCat',
            {
                alignOrigin: [0.5, 0.5],
                rotate: '-245deg',
                ease: 'power1.inOut',
            },
            1.4,
        )
        .to(
            '#iconcat2',
            {
                rotate: '245deg',
                ease: 'power1.inOut',
            },
            1.4,
        )
        .to(
            '#iconcat3',
            {
                rotate: '245deg',
                ease: 'power1.inOut',
            },
            1.4,
        )
        .to(
            '#iconcat4',
            {
                rotate: '245deg',
                ease: 'power1.inOut',
            },
            1.4,
        )
        .to(
            '#iconcat5',
            {
                rotate: '245deg',
                ease: 'power1.inOut',
            },
            1.4,
        )
        .to(
            '#iconcat6',
            {
                rotate: '245deg',
                ease: 'power1.inOut',
            },
            1.4,
        )
        .to(
            '#iconcat2',
            {
                y: '60.%',
                x: '80%',
                ease: 'power1.inOut',
                opacity: 0,
            },
            1.5,
        )
        .to(
            '#rotateCat',
            {
                alignOrigin: [0.5, 0.5],
                rotate: '-293deg',
                // y: '20%',
                ease: 'power1.inOut',
            },
            1.9,
        )
        .to(
            '#iconcat3',
            {
                rotate: '285deg',
                ease: 'power1.inOut',
            },
            1.9,
        )
        .to(
            '#iconcat4',
            {
                rotate: '285deg',
                ease: 'power1.inOut',
            },
            1.9,
        )
        .to(
            '#iconcat5',
            {
                rotate: '285deg',
                ease: 'power1.inOut',
            },
            1.9,
        )
        .to(
            '#iconcat6',
            {
                rotate: '285deg',
                ease: 'power1.inOut',
            },
            1.9,
        )
        .to(
            '#iconcat3',
            {
                y: '40.%',
                x: '-30%',
                ease: 'power1.inOut',
                opacity: 0,
            },
            2,
        )
        .to(
            '#rotateCat',
            {
                alignOrigin: [0.5, 0.5],
                rotate: '-359deg',
                // y: '20%',
                ease: 'power1.inOut',
            },
            2.4,
        )
        .to(
            '#iconcat4',
            {
                rotate: '355deg',
                ease: 'power1.inOut',
            },
            2.4,
        )
        .to(
            '#iconcat5',
            {
                rotate: '355deg',
                ease: 'power1.inOut',
            },
            2.4,
        )
        .to(
            '#iconcat6',
            {
                rotate: '355deg',
                ease: 'power1.inOut',
            },
            2.4,
        )
        .to(
            '#iconcat4',
            {
                y: '-25.%',
                x: '-66%',
                ease: 'power1.inOut',
                opacity: 0,
            },
            2.5,
        )
        .to(
            '#rotateCat',
            {
                alignOrigin: [0.5, 0.5],
                rotate: '-425deg',
                // y: '20%',
                ease: 'power1.inOut',
            },
            2.9,
        )
        .to(
            '#iconcat5',
            {
                rotate: '425deg',
                ease: 'power1.inOut',
            },
            2.9,
        )
        .to(
            '#iconcat6',
            {
                rotate: '425deg',
                ease: 'power1.inOut',
            },
            2.9,
        )
        .to(
            '#iconcat5',
            {
                y: '-45%',
                x: '-77%',
                ease: 'power1.inOut',
                opacity: 0,
            },
            3,
        )
        .to(
            '#rotateCat',
            {
                alignOrigin: [0.5, 0.5],
                rotate: '-473deg',
                ease: 'power1.inOut',
            },
            3.4,
        )
        .to(
            '#iconcat6',
            {
                rotate: '475deg',
                ease: 'power1.inOut',
            },
            3.4,
        )
        .to(
            '#iconcat6',
            {
                y: '-30.%',
                x: '-125%',
                ease: 'power1.inOut',
                opacity: 0,
            },
            3.5,
        );
}

function animateFeed() {
    if (window.matchMedia('(max-width: 1024px)').matches) return;
    gsap.set(['#six-sec-ham1cat', '#six-sec-fish1cat', '#six-sec-ham2cat'], {
        opacity: 1,
        yPercent: 100,
    });
    gsap.set('#six-sec-meatcat', {
        opacity: 1,
        yPercent: 30,
    });

    gsap.timeline({
        scrollTrigger: {
            trigger: wrapCat.value,
            start: '-=0%',
            end: '+=120%',
            scrub: true,
        },
    })
        .to(
            '#feed-pack-open-cat',
            {
                opacity: 1,
                ease: 'power1.inOut',
            },
            1,
        )
        .to(
            '#six-sec-fish1cat',
            {
                yPercent: 7,
            },
            '<',
        )
        .to(
            '#six-sec-ham1cat',
            {
                yPercent: 3,
            },
            '<',
        )
        .to(
            '#six-sec-ham2cat',
            {
                yPercent: -50,
            },
            '<',
        )
        .to(
            '#six-sec-meatcat',
            {
                yPercent: 0,
            },
            '<',
        );
}

function animateFeedClose() {
    if (window.matchMedia('(max-width: 1024px)').matches) return;
    gsap.timeline({
        scrollTrigger: {
            trigger: wrapCat.value,
            start: '-=0%',
            end: '+=1050%',
            pin: true,
            scrub: true,
        },
    })

        .to(
            '#feed-pack-cat',
            {
                opacity: 1,
                ease: 'power1.inOut',
            },
            1,
        )
        .to(
            '#feed-pack-open-cat',
            {
                scale: 0,
                ease: 'power1.inOut',
            },
            '>',
        );
}

onMounted(() => {
    animateFeedClose(), animateFeed(), animateIconCat();
});
</script>

<template>
    <svg
        style="transform: translate(0, 150px)"
        viewBox="0 0 1735 883"
        id="svg"
        class="absolute left-auto -right-[11.23%] lg:block opacity-0 w-[90.59%] h-auto"
    >
        <path
            id="path2"
            d="M1 466.527C14.6313 319.809 63.8078 228.444 172.384 128.83C280.961 29.2152 433.154 1.99912 580.5 1.02667C727.846 0.054219 859.827 25.3948 969.709 123.567C1079.59 221.74 1103.98 421.604 1120 476.527C1156.82 602.779 1251 881.527 1734.5 881.527"
        />
    </svg>
    <div
        class="lg:pt-20 md:pt-10 xl:h-[884px] lg:h-[730px] md:h-112.5 md:pb-0 pb-8.5 overflow-hidden px-5 lg:min-h-screen"
        ref="wrapCat"
    >
        <div
            id="relativeCat"
            class="max-w-[1290px] mx-auto relative xl:pt-40 lg:pt-55 md:pt-35 md:h-auto h-81"
        >
            <img
                id="feed-pack-cat"
                :src="feedPackCat"
                alt="feed pack"
                width="700"
                height="700"
                style="position: absolute; left: 27.5%; z-index: -1"
                class="lg:w-[45%] md:w-101 w-47 h-600 mx-auto absolute left-auto opacity-0"
            />
            <img
                id="feed-pack-open-cat"
                :src="feedPackOpenCat"
                alt="feed pack open"
                width="700"
                height="700"
                style="z-index: -2"
                class="lg:w-[45%] md:w-101 w-47 h-auto mx-auto md:relative relative absolute opacity-0"
            />
            <div
                class="absolute w-full md:max-w-none max-w-82.5 md:mx-0 mx-auto xl:h-[622px] lg:h-[520px] md:h-85 h-81 left-0 top-0"
            >
                <img
                    style="z-index: 10"
                    :src="logoPrem"
                    alt="brit"
                    width="356"
                    height="285"
                    class="absolute hg:-left-20 left-0 lg:-top-12.5 -top-8 h-auto 2xl:w-[267px] lg:w-50 w-35 md:block hidden"
                />
                <div
                    id="rotateCat"
                    class="relative w-full md:max-w-none max-w-82.5 md:mx-0 mx-auto xl:h-[622px] lg:h-[520px] md:h-85 h-81 left-0 top-0 md:relative"
                >
                    <!--Icons-->
                    <IconSixSectionCat1
                        style="z-index: 30"
                        id="iconcat1"
                        class="absolute left-0 md:top-auto top-1/2 md:translate-y-0 -translate-y-1/2 md:bottom-0 2xl:w-auto lg:w-[13.5%] h-auto md:w-22.5 w-15.5"
                    />
                    <IconSixSectionCat2
                        style="z-index: 30"
                        id="iconcat2"
                        class="absolute left-[6.82%] md:top-[27.6%] top-13 2xl:w-auto lg:w-[13.5%] h-auto md:w-22.5 w-12.5"
                    />
                    <IconSixSectionCat3
                        style="z-index: 30"
                        id="iconcat3"
                        class="absolute left-[28.5%] top-0 2xl:w-auto lg:w-[13.5%] h-auto md:w-22.5 w-12.5"
                    />
                    <IconSixSectionCat4
                        style="z-index: 30"
                        id="iconcat4"
                        class="absolute left-auto right-[28.5%] top-0 2xl:w-auto lg:w-[13.5%] h-auto md:w-22.5 w-12.5"
                    />
                    <IconSixSectionCat5
                        style="z-index: 30"
                        id="iconcat5"
                        class="absolute left-auto right-[6.82%] md:top-[27.6%] top-13 2xl:w-auto lg:w-[13.5%] h-auto md:w-22.5 w-12.5"
                    />
                    <IconSixSectionCat6
                        style="z-index: 30"
                        id="iconcat6"
                        class="absolute left-auto right-0 md:top-auto top-1/2 md:translate-y-0 -translate-y-1/2 md:bottom-0 2xl:w-auto lg:w-[13.5%] h-auto md:w-22.5 w-12.5"
                    />
                </div>

                <IconSixSectionCat3
                    class="absolute z-20 h-auto w-12.5 md:hidden block top-auto bottom-13 left-[6.82%]"
                />
                <IconSixSectionCat4
                    class="absolute z-20 h-auto w-12.5 md:hidden block top-auto bottom-0 left-[28.5%]"
                />
                <IconSixSectionCat6
                    class="absolute z-20 h-auto w-12.5 md:hidden block top-auto bottom-0 left-auto right-[28.5%]"
                />
                <IconSixSectionCat2
                    class="absolute z-20 h-auto w-12.5 md:hidden block top-auto bottom-13 left-auto right-[6.82%]"
                />
                <!--End Icons-->

                <!--Decor-->
                <SixSectionFish
                    class="absolute z-0 left-auto md:top-auto top-1/2 md:right-11.5 right-3 md:-bottom-[7.4%] lg:w-auto h-auto md:w-35 w-19.5"
                    id="six-sec-fish1cat"
                />
                <SixSectionFish
                    class="md:hidden block w-19.5 h-auto absolute z-0 left-4.5 top-auto bottom-5"
                />
                <Ham1
                    class="absolute z-0 md:top-[26.2%] top-12 left-auto md:right-[23.25%] right-15 lg:w-auto h-auto md:w-27.5 w-15.5"
                    id="six-sec-ham1cat"
                />
                <Ham1
                    class="md:hidden block w-15.5 h-auto absolute z-0 left-auto right-10 top-auto bottom-7"
                />
                <Ham2
                    class="absolute z-0 md:top-auto top-1/2 md:-bottom-[11.57%] md:left-[6.82%] left-9.5 lg:w-auto h-auto md:w-24.5 w-14"
                    id="six-sec-ham2cat"
                />
                <Meat
                    class="absolute z-0 md:top-[22.5%] top-10 md:left-[12.55%] left-7 lg:w-auto h-auto md:w-25 w-14"
                    id="six-sec-meatcat"
                />
                <Meat
                    class="absolute z-0 md:hidden block h-auto w-14 top-auto -bottom-8.5 left-28"
                />
                <!--End Decor-->
            </div>
        </div>
    </div>
</template>

<style scoped>
html {
    font-size: calc(100vw / 1920);
}

@media (min-width: 1440px) {
    #iconcat1 {
        width: 14.5rem;
        height: 14.5rem;
    }

    #iconcat2 {
        width: 14.5rem;
        height: 14.5rem;
    }

    #iconcat3 {
        width: 14.5rem;
        height: 14.5rem;
    }

    #iconcat4 {
        width: 14.5rem;
        height: 14.5rem;
    }

    #iconcat5 {
        width: 14.5rem;
        height: 14.5rem;
    }

    #iconcat6 {
        width: 14.5rem;
        height: 14.5rem;
    }
}

@media (min-width: 1200px) and (max-width: 1440px) {
    #iconcat1 {
        width: 14.1rem;
        height: 14.1rem;
    }

    #iconcat2 {
        width: 14.1rem;
        height: 14.1rem;
    }

    #iconcat3 {
        width: 14.1rem;
        height: 14.1rem;
    }

    #iconcat4 {
        width: 14.1rem;
        height: 14.1rem;
    }

    #iconcat5 {
        width: 14.1rem;
        height: 14.1rem;
    }

    #iconcat6 {
        width: 14.1rem;
        height: 14.1rem;
    }
}

@media (min-width: 1024px) and (max-width: 1200px) {
    #iconcat1 {
        width: 11.7rem;
        height: 11.3rem;
    }

    #iconcat2 {
        width: 11.7rem;
        height: 11.7rem;
    }

    #iconcat3 {
        width: 11.7rem;
        height: 11.7rem;
    }

    #iconcat4 {
        width: 11.7rem;
        height: 11.7rem;
    }

    #iconcat5 {
        width: 11.7rem;
        height: 11.7rem;
    }

    #iconcat6 {
        width: 11.7rem;
        height: 11.7rem;
    }
}

@media (min-width: 0px) and (max-width: 1024px) {
    #feed-pack-cat {
        opacity: 1;
        left: auto !important;
    }

    #relativeCat {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
